import React from 'react';
import * as AgGrid from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, CellClassParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { AxiosPromise } from 'axios';
import Axios from 'src/services/axios';
import { mapValues, omit, merge, isObject, has, isNumber, cloneDeepWith, isEqual, isEmpty, pickBy } from 'lodash';
import { get, isNil, map, partial } from 'lodash/fp';

import styles from './Lifecycle.styles';
import {
  StyleEditConfigColumn,
  MultiRangeEditors,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import { StyleEditAction } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { ColorHeaderRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import RangePickerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor.container';
import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { BasicItem, DaysRangeListResponse } from 'src/types/Scope';

import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import * as globalMath from 'mathjs';
import { executeCalculation, importDateFunctions, getVarsFromCalcString } from 'src/utils/LibraryUtils/MathUtils';
import { getRangeLists } from 'src/dao/scopeClient';

import AgGridThemeClass from 'src/utils/Style/AgGridTheme1';
import AgGridMaterialThemeNoBorders from 'src/utils/Style/AgGridMaterialThemeNoBorders';
import { DependentData } from '../../StyleEdit/StyleEditSection/StyleEditSection.types';
import { LifecycleData } from 'src/components/LifecycleStoreModal/LifecycleStoreModal';
import { CellValueChangedEvent } from 'ag-grid-community';
import ClearValueCellRenderer from '../../StyleEdit/StyleEditSection/Renderers/ClearValue';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';
import { removeColons } from 'src/utils/Pivot/RemoveColons';
import { RANGE_CODE } from 'src/utils/Domain/Constants';
import service from 'src/ServiceContainer';
import { toast } from 'react-toastify';

// tslint:disable:no-any
type FrameworkComponents = any;
type NonFrameworkComponents = any;
type NewTransposedData = any;
type TransposeRowData = any;
type TransposeRowConfig = any;
type LocalAxiosResponse = any;
// tslint:enable:no-any

type WeekData = {
  weekNo: string;
  date: undefined;
};

type TransposedData = {
  headerText: string;
  dataKey: string;
  calculation: string | undefined;
  id: string;
  label: string;
  refId: string;
  [fieldKey: string]: WeekData | string | undefined;
};

export interface Props {
  weekRangeData: LifecycleData;
  weekRangeFieldEditConfig: MultiRangeEditors | null;
  weekRangeConfig: StyleEditConfigColumn[] | null;
  planningConfig: StyleEditConfigColumn[] | null;
  dependentsData: DependentData | null;
  product?: string;
}

export interface State {
  config: StyleEditConfigColumn[] | null;
  rowData: TransposeRowData;
  transposedData: TransposedData[];
  colDefs: TransposeRowConfig;
  transposedDefs: ColDef[];
  actions?: { [s: string]: StyleEditAction[] };
  multiRangeEditors: MultiRangeEditors | null;
  transposedDataPlanning: TransposedData[];
  transposedDefsPlanning: ColDef[];
  mergedRangeList?: DaysRangeListResponse;
  additionalLifecycleData: LifecycleData;
}

function getDataFromKey(params: ValueGetterParams, key: string) {
  const returnObj = {
    rowNodeFound: false,
    data: undefined,
  };
  const rowNode = params.api!.getRowNode(key);
  if (rowNode) {
    const keyData = rowNode.data[key];
    returnObj.rowNodeFound = true;
    returnObj.data = isObject(keyData) ? (keyData as WeekData).weekNo : keyData;
  }
  return returnObj;
}

export default class Lifecycle extends React.Component<Props, State> {
  savedCalcData: Record<string, any> = {};
  nonFrameworkComponents: FrameworkComponents;
  frameworkComponents: NonFrameworkComponents;
  dataQueue!: AxiosPromise<LocalAxiosResponse>[];
  gridApi!: AgGrid.GridApi;
  columnApi!: AgGrid.ColumnApi;
  gridApi2!: AgGrid.GridApi;
  columnApi2!: AgGrid.ColumnApi;
  observers: {
    [key: string]: string; // key: observed dataIndex, value: observer's dataIndex
  };
  math: globalMath.MathJsStatic = (globalMath as any).create();

  constructor(props: Props) {
    super(props);
    this.nonFrameworkComponents = {
      colorHeaderRenderer: ColorHeaderRenderer,
    };
    this.frameworkComponents = {
      imageCellRenderer: ImageCellRenderer,
      validValuesEditor: ValidValuesEditor,
      rangePickerRenderer: RangePickerRenderer,
      rangePickerEditor: RangePickerEditor,
      validSizesRenderer: ValidSizesRenderer,
      integerEditor: IntegerEditor,
      clearValueRenderer: ClearValueCellRenderer,
    };
    this.observers = {};

    // Parse data from server
    const rowData = {};
    Object.keys(this.props.weekRangeData).forEach((key) => {
      if (typeof this.props.weekRangeData[key] === 'string' && key !== 'ccrangecode') {
        rowData[key] = {
          weekNo: this.props.weekRangeData[key],
          date: undefined,
        };
      } else {
        rowData[key] = this.props.weekRangeData[key];
      }
    });

    this.state = {
      config: props.weekRangeConfig,
      transposedDefs: [],
      colDefs: props.weekRangeConfig,
      rowData,
      transposedData: [],
      transposedDataPlanning: [],
      transposedDefsPlanning: [],
      mergedRangeList: undefined,
      multiRangeEditors: props.weekRangeFieldEditConfig,
      additionalLifecycleData: {},
    };
  }

  componentDidMount() {
    getRangeLists().then((resp) => {
      const { daysRangeListExtended: daysRangeList, daysPastRangeList } = resp;
      const mergedRangeList = {
        start_date: merge(daysRangeList.start_date, daysPastRangeList.start_date),
        end_date: merge(daysRangeList.end_date, daysPastRangeList.end_date),
      };
      importDateFunctions(this.math, mergedRangeList);
      this.setState(
        {
          mergedRangeList,
        },
        () => {
          const { rowData, config } = this.state;
          const { planningConfig } = this.props;
          this.transposeRowsAndColumns(rowData, !isNil(config) ? config : []);
          this.transposeRowsAndColumnsPlanning(rowData, !isNil(planningConfig) ? planningConfig : []);
        }
      );
    });
  }

  checkDisabledColumnValue(disableValue: boolean | number, value: boolean | number) {
    return disableValue === value;
  }

  createColumn = (
    colDefs: StyleEditConfigColumn[],
    styleColor: BasicItem,
    processedColInfo: TransposedData
  ): ColDef => {
    const isEditable = (params: { node?: AgGrid.RowNode }) => {
      const rowIndex = get('node.rowIndex', params);
      const colInfo = get(rowIndex, colDefs);
      colInfo.controlsColumnDisable = !!colInfo.controlsColumnDisable;

      if (colInfo) {
        return colInfo.editable;
      } else {
        return false;
      }
    };

    return {
      headerName: processedColInfo.id,
      field: processedColInfo.id,
      cellStyle: (params) => {
        // Valid sizes needs to scroll in the cell
        if (params.node.id === 'validsizes') {
          return {
            width: '320px',
          };
        }
        return undefined;
      },
      cellClassRules: {
        [styles.uneditable]: (params: CellClassParams) => {
          const rowIndex = get('node.rowIndex', params);
          const colInfo = get(rowIndex, colDefs);
          return !colInfo.editable;
        },
      },
      valueGetter: (params) => {
        const calculation = params.data.calculation;
        const getDataFn = partial(getDataFromKey, [params]);

        if (calculation) {
          if (params.data.onlyCalcOnParamChange) {
            let paramChangedCalc = false;
            const vars = getVarsFromCalcString(this.math, calculation);
            vars.forEach((v: string) => {
              const varValue = getDataFn(v);
              if (Object.keys(this.savedCalcData).indexOf(v) === -1) {
                this.savedCalcData[v] = varValue;
              }
              if (!isEqual(varValue, this.savedCalcData[v])) {
                paramChangedCalc = true;
              }
            });
            if (!paramChangedCalc) {
              return params.data[params.data.id];
            }
          }

          const node = params.node;
          const id = node.data.id;
          const newValue = executeCalculation(this.math, calculation, getDataFn);
          const currentData = node.data[id];
          node.setDataValue(id, newValue);

          if (!isNil(currentData)) {
            // also set internal data
            node.data[id] =
              isObject(currentData) && has(currentData, 'weekNo') ? { ...currentData, weekNo: newValue } : newValue;
          } else {
            node.data[id] = isNumber(newValue) ? newValue : { weekNo: newValue, date: undefined };
          }

          return newValue;
        }

        if (params.data) {
          return params.data[params.data.id];
        }

        return null;
      },
      valueSetter: (params) => {
        if (params.newValue || (!params.newValue && isNumber(params.newValue))) {
          params.data[params.data.id] = params.newValue;
        }

        if (params.node.id === 'ccrangecode' && params.newValue && params.api) {
          const { dependentsData } = this.props;
          const rowNodeSizes = params.api.getRowNode('validsizes');
          const newValSizes: string[] = [];
          const newValRange = params.newValue.value ? params.newValue.value : params.newValue;

          if (!isNil(dependentsData)) {
            dependentsData[newValRange].forEach((key: string) => {
              newValSizes.push(key);
            });
          }

          // Set valid sizes and size range
          rowNodeSizes.data['validsizes'] = newValSizes;
          params.data[params.data.id] = newValRange;
        }
        return true;
      },
      valueFormatter: (params) => {
        if (params.value) {
          if (params.value.weekNo) {
            return params.value.weekNo;
          } else {
            return params.value;
          }
        } else {
          return null;
        }
      },
      editable: (params) => {
        const inlineEditors = ['validSizes'];
        const rowIndex = get('node.rowIndex', params);
        const colInfo = get(rowIndex, colDefs);
        colInfo.controlsColumnDisable = colInfo.controlsColumnDisable ? colInfo.controlsColumnDisable : false;
        let editable = false;

        if (colInfo) {
          editable = colInfo.editable && inlineEditors.indexOf(colInfo.renderer!) < 0;
        }

        return editable;
      },
      cellEditorSelector: (params) => {
        const rowIndex = get('node.rowIndex', params);
        const colInfo = get(rowIndex, colDefs);
        const cleanedStyleColor = cloneDeepWith(styleColor, (value) => {
          if (has(value, 'weekNo')) {
            return get('weekNo', value);
          }
        });
        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'multiRangeEditors':
            const { disableOverlap } = colInfo;
            const { multiRangeEditors } = this.state;
            const multiRangeDataIndices =
              !isNil(multiRangeEditors) && !isNil(colInfo.inputComponent)
                ? multiRangeEditors[colInfo.inputComponent]
                : [];
            const checkboxIndices = !isNil(multiRangeEditors) ? multiRangeEditors.checkboxEditor : undefined;
            const col = colInfo.dataIndex;
            return {
              component: 'rangePickerEditor',
              params: {
                values: map('value', colInfo.options),
                styleOverrides: {
                  position: 'fixed',
                  left: '30%',
                  top: '30%',
                  zIndex: 99999,
                },
                multiRangeDataIndices,
                disableOverlap,
                startWithPlanCurrent: true,
                checkboxIndices,
                col,
                lifecycleData: styleColor,
                updateLifecycleData: (_styleColorId: string, data: LifecycleData) =>
                  this.setState({ additionalLifecycleData: { ...this.state.additionalLifecycleData, ...data } }),
              },
            };
          case 'integer':
            let int = params.data[params.data.dataKey];
            if (isObject(int)) {
              int = int['weekNo'];
            }
            const percent = colInfo.renderer === 'percent';
            return {
              component: 'integerEditor',
              params: {
                passedInt: int,
                inputParams: { ...colInfo.inputParams, percent },
              },
            };
          case 'popoverSelect':
            const { text: title, inputComponent } = colInfo;
            const processedDataApi = processApiParams(colInfo.dataApi, cleanedStyleColor);

            return {
              component: 'popoverSelectEditor',
              params: {
                title,
                inputComponent,
                dataApi: processedDataApi,
                configApi: {
                  url: colInfo.configApi.url,
                  params: colInfo.configApi.params,
                },
              },
            };
          case 'validValuesNoServer': {
            const { dependentsData } = this.props;
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            return {
              component: 'validValuesEditor',
              params: {
                options: !isNil(dependentsData) ? Object.keys(dependentsData) : null,
                dataQa: 'select-no-server-editor',
                allowEmptyOption,
              },
            };
          }
          case 'validValues': {
            const processedConfigApi = processApiParams(colInfo.dataApi, cleanedStyleColor);
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedConfigApi,
                dataQa: 'select-lifecycle',
                asCsv: colInfo.asCsv,
                ignoreCache: colInfo.ignoreCache,
                allowEmptyOption,
              },
            };
          }
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'receiptsAdjCalculator':
            return {
              component: 'receiptsAdjCalculator',
              params: {
                isEditable: isEditable(params),
                dataApi: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (_v, k) => {
                    return styleColor[k];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          default:
            return {
              component: 'agTextCellEditor',
            };
        }
      },
      cellRendererSelector: (params): ComponentSelectorResult => {
        const rowIndex = get('node.rowIndex', params);
        const colInfo = get(rowIndex, colDefs);
        switch (colInfo.renderer) {
          case 'image':
            return {
              component: 'imageCellRenderer',
            };
          case 'icon':
            return {
              component: 'iconCellRenderer',
              params: {
                icon: colInfo.rendererIcon,
              },
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
              params: colInfo,
            };
          case 'validSizes':
            if (colInfo.dataApi) {
              // allow component to manage own data
              const dataParams = colInfo.dataApi.params;
              const transposedData: Record<string, unknown> = {};
              if (dataParams != null) {
                Object.values(dataParams).forEach((valKey) => {
                  const rowNode = params.api.getRowNode(valKey);
                  if (rowNode != null) {
                    transposedData[valKey] = rowNode.data[valKey];
                  }
                });
              }
              const validSizesDataApi = processApiParams(colInfo.dataApi, transposedData);
              return {
                component: 'validSizesRenderer',
                params: {
                  selectedOptions: params.data[colInfo.dataIndex],
                  options: [],
                  dataApi: validSizesDataApi,
                  editable: isEditable(params),
                },
              };
            } else {
              const { dependentsData } = this.props;
              const rangecode = params.api.getRowNode(RANGE_CODE);
              const dependentSizes =
                !isNil(dependentsData) && rangecode.data && rangecode.data.ccrangecode
                  ? dependentsData[rangecode.data.ccrangecode]
                  : null;
              const options = dependentSizes ? dependentSizes : params.data['validsizes'];

              return {
                component: 'validSizesRenderer',
                params: {
                  options: options || [],
                  editable: true,
                  selectedOptions: params.data[colInfo.dataIndex],
                },
              };
            }
          case 'clearValue':
            return {
              component: 'clearValueRenderer',
              params: {
                isDataTransposed: false,
              },
            };
          default:
            return (null as unknown) as ComponentSelectorResult;
        }
      },
    };
  };

  transposeRowsAndColumns(rowData: TransposeRowData, colDefs: TransposeRowConfig) {
    const newTransposedData: NewTransposedData = colDefs.map((colDef: TransposeRowConfig) => {
      const returnData = {
        headerText: colDef.text,
        dataKey: colDef.dataIndex,
        label: colDef.dataIndex,
        id: colDef.dataIndex,
        refId: colDef.id,
        calculation: colDef.calculation,
        inputParams: colDef.inputParams,
        onlyCalcOnParamChange: colDef.onlyCalcOnParamChange,
        inputType: colDef.inputType,
      };
      returnData[colDef.dataIndex] = rowData[colDef.dataIndex];
      return returnData;
    });

    const newColData: ColDef[] = [
      {
        headerName: '',
        field: 'headerText',
        cellStyle: { 'font-size': 'large' },
        pinned: 'left',
      },
    ].concat(newTransposedData.map(partial(this.createColumn, [colDefs, rowData])));

    this.setState({
      transposedData: newTransposedData,
      transposedDefs: [newColData[0], newColData[1]],
    });
  }

  transposeRowsAndColumnsPlanning(rowData: TransposeRowData, colDefs: TransposeRowConfig) {
    const newTransposedData: NewTransposedData = colDefs.map((colDef: TransposeRowConfig) => {
      const returnData = {
        headerText: colDef.text,
        dataKey: colDef.dataIndex,
        label: colDef.dataIndex,
        id: colDef.dataIndex,
        refId: colDef.id,
        calculation: colDef.calculation,
        onlyCalcOnParamChange: colDef.onlyCalcOnParamChange,
        inputType: colDef.inputType,
      };
      returnData[colDef.dataIndex] = rowData[colDef.dataIndex];
      return returnData;
    });

    const newColData: ColDef[] = [
      {
        headerName: '',
        field: 'headerText',
        cellStyle: { 'font-size': 'large' },
        pinned: 'left',
      },
    ].concat(newTransposedData.map(partial(this.createColumn, [colDefs, rowData])));

    this.setState({
      transposedDataPlanning: newTransposedData,
      transposedDefsPlanning: [newColData[0], newColData[1]],
    });
  }

  handleCellValueChange = (params: CellValueChangedEvent) => {
    const { colDef, data, api: gridApi } = params;
    const { field } = colDef; // store field (dataKey) of modified column to know which data field to modify
    const { dataKey: observed } = data;
    const observerDataKey = this.observers[observed]; // get dataKey of row to clear

    if (!isNil(gridApi) && !isNil(field) && !isNil(observerDataKey)) {
      gridApi.forEachNode((node) => {
        if (node.data.dataKey === observerDataKey) {
          node.setDataValue(field, '');
        }
      });
    }
  };

  getLifecycleData = (): LifecycleData | null => {
    const { additionalLifecycleData } = this.state;
    const rowData: TransposeRowData[] = [];
    const rowDataPlanning: TransposeRowData[] = [];
    this.gridApi.forEachLeafNode((node) => {
      rowData.push(node.data);
    });
    this.gridApi2.forEachLeafNode((node) => {
      rowDataPlanning.push(node.data);
    });

    const oneObjectData: Record<string, any> = {};
    rowData.concat(rowDataPlanning).forEach((data) => {
      if (data.dataKey !== 'firstcol') {
        if (has(data[data.dataKey], 'weekNo')) {
          oneObjectData[data.dataKey] = data[data.dataKey].weekNo;
        } else {
          oneObjectData[data.dataKey] = data[data.dataKey];
        }
      }
    });

    let mergedObject = Object.assign({}, this.state.rowData, oneObjectData);
    mergedObject = mapValues(mergedObject, function(value: unknown) {
      if (has(value, 'weekNo')) {
        return (value as WeekData).weekNo;
      } else {
        return value;
      }
    });

    const { weekRangeConfig, planningConfig } = this.props;
    if (isNil(weekRangeConfig) || isNil(planningConfig)) {
      return null;
    }
    // Only send properties from the config
    const configKeys: string[] = weekRangeConfig.concat(planningConfig).map((config) => config.dataIndex);
    const finalObject: LifecycleData = {};
    configKeys.forEach((key) => (finalObject[key] = mergedObject[key]));
    delete finalObject['firstcol'];

    if (additionalLifecycleData) {
      Object.keys(additionalLifecycleData).forEach((x) => {
        finalObject[x] = additionalLifecycleData[x];
      });
    }
    return finalObject;
  };

  getLifecyclePostPromise = async () => {
    if (this.props.product) {
      const originalData = removeColons(this.props.weekRangeData || {});
      const curAttrs = removeColons(this.getLifecycleData() || {});
      const changedAttrs = pickBy(curAttrs, (v, k) => {
        return !isEqual(v, originalData[k]);
      });
      const finalData = {
        product: this.props.product,
        attributes: changedAttrs,
      };

      // Get promise for parent
      const code = !isNil(finalData) && !isNil(finalData.attributes) ? finalData.attributes.ccrangecode : '';
      try {
        await Axios.post(`api/assortment/lifecycleParams?appName=Assortment`, {
          product: finalData.product,
          attributes: {
            ccrangecode: code,
          },
        });
        const result = await Axios.post(`api/assortment/lifecycleParams?appName=Assortment`, {
          ...finalData,
          attributes: omit(finalData.attributes, ['ccrangecode']),
        });
        return result;
      } catch (error: any) {
        toast.error(`${error.message}`);
        service.loggingService.error(`${error.message}`);
        return null;
      }
    } else {
      return null;
    }
  };

  postLifecycleData = () => {
    return this.getLifecyclePostPromise();
  };

  render() {
    const { config } = this.state;
    if (
      !config ||
      !this.state.transposedData ||
      isEmpty(this.state.transposedData) ||
      isEmpty(this.state.transposedDataPlanning)
    ) {
      return <div className={styles.container} style={{ minHeight: '260px' }} />;
    }

    return (
      <div className={styles.container} style={{ minHeight: '260px' }}>
        <div className={`${AgGridThemeClass} ${AgGridMaterialThemeNoBorders} ${styles.headersContainer}`}>
          <AgGridReact
            domLayout="autoHeight"
            rowHeight={30}
            rowData={this.state.transposedData}
            columnDefs={this.state.transposedDefs.map((x) => {
              x.suppressKeyboardEvent = (params: AgGrid.SuppressKeyboardEventParams) => {
                if (params.data && params.editing) {
                  return true;
                }
                return false;
              };
              return x;
            })}
            singleClickEdit={true}
            suppressHorizontalScroll={true}
            components={this.nonFrameworkComponents}
            frameworkComponents={this.frameworkComponents}
            headerHeight={0}
            getRowNodeId={(data) => {
              return data.id;
            }}
            onGridReady={(params: AgGrid.GridReadyEvent) => {
              if (params.api && params.columnApi) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
              }
            }}
            onCellEditingStopped={() => this.forceUpdate()}
            onCellValueChanged={this.handleCellValueChange}
          />
        </div>
        <div className={`${AgGridThemeClass} ${AgGridMaterialThemeNoBorders} ${styles.columnsContainer}`}>
          <AgGridReact
            domLayout="autoHeight"
            rowData={this.state.transposedDataPlanning}
            columnDefs={this.state.transposedDefsPlanning}
            singleClickEdit={true}
            suppressHorizontalScroll={true}
            components={this.nonFrameworkComponents}
            frameworkComponents={this.frameworkComponents}
            headerHeight={0}
            getRowNodeId={(data) => {
              return data.id;
            }}
            onGridReady={(params: AgGrid.GridReadyEvent) => {
              if (params.api && params.columnApi) {
                this.gridApi2 = params.api;
                this.columnApi2 = params.columnApi;
              }
            }}
            onCellEditingStopped={() => this.forceUpdate()}
            onCellValueChanged={this.handleCellValueChange}
            getRowStyle={(params: AgGrid.ICellRendererParams) => {
              // Valid sizes needs to scroll in the cell
              if (this.props.planningConfig) {
                const propColDef = this.props.planningConfig.find((colDef) => {
                  return colDef.dataIndex === params.node?.id;
                });
                if (propColDef != null && propColDef.renderer === 'validSizes') {
                  return {
                    overflow: 'auto',
                  };
                }
              }
              return undefined;
            }}
            getRowHeight={(params: AgGrid.ICellRendererParams) => {
              if (this.props.planningConfig) {
                const propColDef = this.props.planningConfig.find((colDef) => {
                  return colDef.dataIndex === params.node?.id;
                });
                if (propColDef != null && propColDef.renderer === 'validSizes') {
                  return 50;
                }
              }
              return 30;
            }}
            stopEditingWhenGridLosesFocus={true}
          />
        </div>
      </div>
    );
  }
}
