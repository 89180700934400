import React, { useRef, useState } from 'react';
import { Popover, Typography } from '@material-ui/core';
import { important, px } from 'csx';
import { PlanItem, PlanItemStatus } from 'src/pages/AssortmentBuild/Planning.slice';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import styles from 'src/components/Headerbar/Headerbar.styles';

const PlanningLinkContainerStyle: React.CSSProperties = {
  maxWidth: important(px(145)),
};

const LinkContainerStyle: React.CSSProperties = {
  maxWidth: important(px(115)),
};

interface PlanRefreshReadyProps {
  onRefresh: () => void;
}

const PlanRefreshReady = ({ onRefresh }: PlanRefreshReadyProps) => {
  return (
    <IconLink
      id="PlanRefreshReady"
      iconClass={''}
      linkClass="clickable refreshable"
      containerStyle={LinkContainerStyle}
      text="Plan Updated. Click to refresh."
      onClick={onRefresh}
    />
  );
};

interface PlanPopoverContentProps {
  pending: number;
  processing: number;
  completed: number;
  failed: number;
}

const PlanPopoverContent = ({ pending, processing, completed, failed }: PlanPopoverContentProps) => {
  return (
    <React.Fragment>
      <Typography>Planning Status: </Typography>
      <ul>
        <li>
          <div className="list-popover-container">
            <div>Pending: {pending}</div>
          </div>
        </li>
        <li>
          <div className="list-popover-container">
            <div>Processing: {processing}</div>
          </div>
        </li>
        <li>
          <div className="list-popover-container">
            <div>Completed: {completed}</div>
          </div>
        </li>
        <li>
          <div className="list-popover-container">
            <div>Failed: {failed}</div>
          </div>
        </li>
      </ul>
    </React.Fragment>
  );
};

interface PlanLinkProps {
  planInProgress: boolean;
  askForRefresh: boolean;
  planItems: PlanItem[];
  onClick: () => void;
  onRefresh: () => void;
}

export const PlanLink = ({ planInProgress, askForRefresh, planItems, onClick, onRefresh }: PlanLinkProps) => {
  const anchorElement = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(false);
    onClick();
  };
  const handleMouseOver = () => setOpen(true);
  const handleMouseLeave = () => setOpen(false);

  const pending = planItems.filter(({ status }) => status === PlanItemStatus.Pending);
  const itemsPending = pending.length > 0;
  const processing = planItems.filter(({ status }) => status === PlanItemStatus.Processing);
  const completed = planItems.filter(({ status }) => status === PlanItemStatus.Completed);
  const failed = planItems.filter(({ status }) => status === PlanItemStatus.Failed);

  if (askForRefresh) {
    return <PlanRefreshReady onRefresh={onRefresh} />;
  } else {
    return (
      <React.Fragment>
        <IconLink
          id="PlanLink"
          iconClass={!planInProgress ? 'fa fa-paper-plane' : 'fa fa-spinner fa-spin'}
          containerStyle={PlanningLinkContainerStyle}
          text={!planInProgress ? `Plan Queue ${itemsPending ? `(${pending.length})` : ''}` : 'Planning in progress'}
          showBadge={itemsPending}
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          ref={anchorElement}
        />
        <Popover
          open={isOpen}
          classes={{ root: styles.filterPopover }}
          anchorEl={anchorElement.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          container={anchorElement.current}
          PaperProps={{
            onMouseEnter: handleMouseOver,
            onMouseLeave: handleMouseLeave,
          }}
          disableRestoreFocus={true}
        >
          <PlanPopoverContent
            pending={pending.length}
            processing={processing.length}
            completed={completed.length}
            failed={failed.length}
          />
        </Popover>
      </React.Fragment>
    );
  }
};
