import * as React from 'react';
import {
  ColDef,
  ColGroupDef,
  ValueGetterParams,
  IsColumnFuncParams,
  SuppressKeyboardEventParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { GridReadyEvent, BodyScrollEvent, CellValueChangedEvent } from 'ag-grid-community/dist/lib/events';
import { Checkbox, IconButton, Icon } from '@material-ui/core';
import { classes } from 'typestyle';
import * as _ from 'lodash';
import { CSSProperties } from 'react';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { ColumnApi } from 'ag-grid-community/dist/lib/columnController/columnApi';
import 'ag-grid-enterprise';
import styles, { MUIStyles } from './FlowSheetGrid.styles';
import { TimeEntry } from 'src/worker/pivotWorker.types';
import Renderer from 'src/utils/Domain/Renderer';
import {
  EDITABLE_BG_COLOR,
  FlowSheetCellRendererParams,
  FlowSheetGridProps,
  FlowSheetGridState,
  getId,
  IS_PUBLISHABLE_INDEX,
  LOCKED_INDEX,
  OVERRIDE_VRP_INDEX,
  PUBLISH_INDEX,
  RowData,
  TEST_PO,
  FLOORSET_PO,
  DC_USERADJ,
  DC_FINREV,
  SYS_VRP_INDEX,
  DC_ONORDER,
  GridMeasureDefn,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { default as ReceiptsAdjCalculator } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TabbedReceiptsAdjCalculator';
import AgGridHeaderBreakClass from 'src/utils/Style/AgGridThemeBreakHeaders';
import { isNil, isBoolean, isEmpty, isEqual, get, set, groupBy, map, reduce, sortBy } from 'lodash';
import { GetMainMenuItemsParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { ASSORTMENT, BLOCK_ENTER_EDITORS, IS_PUBLISHED, POPOVER_BLOCK_CODES } from 'src/utils/Domain/Constants';
import * as globalMath from 'mathjs';
import { executeCalculation } from 'src/utils/LibraryUtils/MathUtils';
import { ICellEditorParams, ICellRendererParams, ProcessCellForExportParams } from 'ag-grid-community';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import {
  viewDefnWhitelistToNarrowedCharacterWhitelist,
  TextValidationEditor,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import TooltipRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import PublishGridRenderer from 'src/components/PublishGridRenderer/PublishGridRenderer';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';
import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import {
  convertPivotItemsToRowData,
  getGridRowHeight,
  getPayloadValueFromInputParams,
  getValidTypedValue,
} from './FlowSheet.utils';
import { multiHeaderDecorate } from 'src/common-ui/components/DataGrid/NestedHeader';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import coalesce from 'src/utils/Functions/Coalesce';
import Adornments from 'src/components/Adornments/Adornments';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

export const MAX_COLS_VISIBLE = 20; // this is slightly higher than necessary but ensures functionality is correct
export const FLOWSHEET_HEADER_HEIGHT = 56;
export const TEMP_REC_ADJ_CONFIG_API = {
  // FIXME: it's hardcode atm because FlowSheet does not provide an easy way to access
  // the original column definitions.
  url: '/api/uidefn/config?appName=Assortment&defnId=ReceiptAdjTemp',
};

export function getRecAdjDataApi(productId: string, timeId: string | undefined) {
  return {
    url: '/api/assortment/adjustments/receipts',
    params: {
      appName: ASSORTMENT,
      productId,
      timeId,
      defnId: 'ReceiptAdjustments',
    },
  };
}

type ItemToColDef = Record<string, unknown> & { id: string; description: string };

function _convertBooleanToNumber(a: boolean | number): number {
  if (isBoolean(a)) {
    return a ? 1 : 0;
  } else {
    return a;
  }
}

class FlowSheetCellRenderer extends React.Component<FlowSheetCellRendererParams> {
  constructor(props: FlowSheetCellRendererParams) {
    super(props);
  }

  render() {
    const colDef = this.props.colDef;
    const rowNode = this.props.node;
    const data = this.props.node.data;
    const field = colDef.field || '';
    const groupId = data.groupId;
    const measureId = data.measureId;
    const component = this.props.context.componentParent;

    const isEditable = this.props.editable(rowNode, colDef);
    const isVisiblyEditable = isEditable ? EDITABLE_BG_COLOR : undefined;

    const valueStyle: CSSProperties = {
      backgroundColor: isVisiblyEditable,
      width: '100%',
      height: '100%',
      whiteSpace: 'pre',

      // Fixes for centering columns
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const renderFn = Renderer[data.renderer || ''] || ((a: string | number | undefined) => a);

    if (data.calculation && this.props.value) {
      return <div style={valueStyle}>{renderFn(this.props.value)}</div>;
    }

    if (data.path.length === 2) {
      const value = data.extraData[field];

      if (data.renderer == 'checkbox' && measureId) {
        const hasInputParamsAndInputType =
          data.inputParams && data.inputParams.inputType && typeof data.inputParams.inputType === 'string';

        const checkedValue = hasInputParamsAndInputType
          ? getPayloadValueFromInputParams(value, 'boolean')
          : getValidTypedValue(value);

        const iconClass = !checkedValue ? 'far fa-square' : 'far fa-check-square';
        const isDisabled = !isEditable;
        const disabledClass = isDisabled ? 'icon-disabled' : '';
        const icon = <i className={classes(iconClass, disabledClass)} />;

        return (
          <IconButton
            classes={MUIStyles.IconButton}
            disabled={isDisabled}
            onClick={() => {
              const rowNode = this.props.api.getRowNode(getId(data.groupId, measureId));
              const oldValue = getValidTypedValue(rowNode.data.extraData[field]);
              const newValue = isNil(oldValue) || oldValue === 0 ? 1 : 0;

              const outgoingValue = hasInputParamsAndInputType
                ? getPayloadValueFromInputParams(newValue, data.inputParams.inputType)
                : newValue;

              component.updateCell(groupId, measureId, field, outgoingValue);
            }}
          >
            {icon}
          </IconButton>
        );
      } else if (measureId === OVERRIDE_VRP_INDEX) {
        let isDisabled = !isEditable;
        let disabledClass = isDisabled ? 'icon-disabled' : '';
        if (!data.extraData[field + '_dependentData']) {
          return <div />;
        }

        // Simpler to change the 0 and 1s to false and true since how grid works already
        const userVrp = !!value;
        const sysVrp = !!data.extraData[field + '_dependentData'][SYS_VRP_INDEX];
        const onOrder = data.extraData[field + '_dependentData'][DC_ONORDER];
        let iconClass;

        if (!isNil(onOrder) && onOrder > 0) {
          // check and disable receipt week checkbox if onorders are present
          iconClass = 'far fa-check-square';
          disabledClass = 'icon-disabled';
          isDisabled = true;
        } else if (sysVrp === true) {
          if (userVrp === true) {
            iconClass = 'far fa-square';
          } else {
            iconClass = 'far fa-check-square';
          }
        } else {
          if (userVrp === true) {
            iconClass = `far fa-check-square ${styles.isOverride}`;
          } else {
            iconClass = 'far fa-square';
          }
        }

        const icon = <i className={`${iconClass} ${disabledClass}`} />;
        return (
          <IconButton
            classes={MUIStyles.IconButton}
            onClick={() => {
              const oldValue = this.props.api.getRowNode(getId(data.groupId, OVERRIDE_VRP_INDEX)).data.extraData[field];
              const newValue = !!oldValue ? 0 : 1;
              component.updateCell(groupId, OVERRIDE_VRP_INDEX, field, newValue);
            }}
            disabled={!isEditable}
          >
            {icon}
          </IconButton>
        );
      } else if (measureId === LOCKED_INDEX) {
        let icon = <i className={`far fa-lock`} />;
        if (!value) {
          icon = <i className={`far fa-lock-open`} />;
        }
        return (
          <IconButton
            classes={MUIStyles.IconButton}
            disabled={!isEditable}
            onClick={() => {
              component.updateCell(groupId, LOCKED_INDEX, field, _convertBooleanToNumber(!value));
            }}
          >
            {icon}
          </IconButton>
        );
      } else if (measureId === PUBLISH_INDEX) {
        const isPublishableNode = this.props.api.getRowNode(getId(data.groupId, IS_PUBLISHABLE_INDEX));
        const isPublishableData: RowData = isPublishableNode.data;
        const isPublishable = !!isPublishableData.extraData[field];
        return (
          <Checkbox
            classes={MUIStyles.IconButton}
            icon={<Icon className={'far fa-circle'} />}
            checkedIcon={<Icon className={'far fa-check-circle'} />}
            onClick={() => {
              component.updateCell(groupId, PUBLISH_INDEX, field, _convertBooleanToNumber(!value));
              if (!value) {
                // lock follows publish per Chetan's request
                _.defer(() => {
                  component.updateCell(groupId, LOCKED_INDEX, field, _convertBooleanToNumber(!value));
                });
              } else {
                _.defer(() => {
                  component.updateCell(groupId, LOCKED_INDEX, field, 0.0);
                });
              }
              component.refreshField(field);
            }}
            checked={!!value}
            disabled={!isEditable}
          />
        );
      }

      const isEmpty = value === null || typeof value === 'undefined' || String(value).trim() === '';
      const display = isEmpty ? '' : renderFn(value);
      return (
        <div className="flowsheet-cell-renderer" style={valueStyle}>
          {display}
        </div>
      );
    }

    return null;
  }
}

class HeaderCellRenderer extends React.Component<FlowSheetCellRendererParams> {
  constructor(props: FlowSheetCellRendererParams) {
    super(props);
  }

  onToggle = (_event: React.MouseEvent<HTMLSpanElement>) => {
    const { data, onHeaderClick } = this.props;

    if (onHeaderClick) {
      onHeaderClick({
        id: data.id,
        parentId: data.additionalId,
      });
    }
  };

  render() {
    const params = this.props;
    const data = params.data;
    let textName = data.name || data.measureId;
    const level = data.path.length;
    const adornments: AdornmentType[] = this.props.getAdornments();

    if (level === 1) {
      textName = data.name || data.groupId;
      if (adornments.length) {
        return (
          <div style={{ display: 'flex' }} className={`${styles.headerName}`}>
            <Adornments adornments={adornments} productId={data.id} />
            <span className={`level-${level}`} onClick={this.onToggle}>
              {textName}
            </span>
          </div>
        );
      }
      return (
        <span className={`${styles.headerName} level-${level}`} onClick={this.onToggle}>
          {textName}
        </span>
      );
    }
    const configClasses = data.classes?.join(' ');
    const classes = `${styles.headerName} level-${level} ${configClasses}`;

    return <span className={classes}>{textName}</span>;
  }
}

export class FlowSheetGrid extends React.Component<FlowSheetGridProps, FlowSheetGridState> {
  private _isUnMounted = false;
  protected gridApi!: GridApi;
  protected columnApi!: ColumnApi;
  protected GRID_EXPORT_NAME = 'Flowsheet-Export';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  math: globalMath.MathJsStatic = (globalMath as any).create();

  constructor(props: FlowSheetGridProps) {
    super(props);
    this.math.import(
      {
        nil: (v: unknown) => v != null,
      },
      { silent: true }
    );

    const anchorField = props.anchorField || '';
    this.state = {
      anchorField,
      columnDefs: [],
      context: { componentParent: this },
      rowData: [],
      rowDataOld: [],
      frameworkComponents: {
        flowSheetCellRenderer: FlowSheetCellRenderer,
        headerCellRenderer: HeaderCellRenderer,
        receiptsAdjCalculator: ReceiptsAdjCalculator,
        integerEditor: IntegerEditor,
        textValidationEditor: TextValidationEditor,
        tooltipRenderer: TooltipRenderer,
        publishRenderer: PublishGridRenderer,
        validValuesEditor: ValidValuesEditor,
      },
    };

    this.onGridReady = this.onGridReady.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
  }

  componentDidMount() {
    if (this.props.onRenderGrid) {
      this.props.onRenderGrid();
    }
  }

  updateColumnDefs = () => {
    const configuredTimeLevels = this.props.gridDefn?.timeLevels;
    let columnDefs: (ColDef | ColGroupDef)[] = [];
    if (isNil(configuredTimeLevels)) {
      columnDefs = this.decorateWithStyleClass(this.props.scopeTimeEntries, 0, this.props.anchorField || '');
    } else {
      columnDefs = this.generateConfiguredTimeColDefs();
    }

    this.setState(
      {
        columnDefs,
      },
      () => {
        if (this.gridApi != null && !this._isUnMounted) {
          this.gridApi?.setColumnDefs([]);
          this.gridApi.setColumnDefs(multiHeaderDecorate(this.state.columnDefs || []));
          this.gridApi.refreshCells({ force: true });
          // We always scroll back to week start when time changes (INT-3249)
          this.scrollAnchorIntoView(this.gridApi, this.columnApi);
        }
      }
    );
  };

  componentDidUpdate(prevProps: FlowSheetGridProps) {
    if (
      this.props.onCompanionItemChange &&
      !isEmpty(prevProps.itemId) &&
      !isEqual(prevProps.itemId, this.props.itemId)
    ) {
      this.props.onCompanionItemChange();
    }
    // Check if time period has changed or favorites for time period has changed
    if (!isEmpty(this.props.scopeTimeEntries) && !isNil(this.props.gridDefn)) {
      if (
        !isEqual(this.props.gridDefn?.timeLevels, prevProps.gridDefn?.timeLevels) ||
        !isEqual(this.props.scopeTimeEntries, prevProps.scopeTimeEntries) ||
        isEmpty(this.state.columnDefs) // safety measure, triggers when coldefs would change
      ) {
        this.updateColumnDefs();
      }
    }
    // update rows on favorites change or new data loaded in
    if (!isNil(this.props.gridDefn) && !isNil(this.props.overTimeData)) {
      if (
        !isEqual(prevProps.overTimeData, this.props.overTimeData) ||
        !isEqual(prevProps.gridDefn, this.props.gridDefn)
      ) {
        const [_topDataLevel, leafDataLevel] = this.props.gridDefn.timeLevels?.data || [];
        // @ts-ignore
        const gridView = (this.props.gridDefn?.view || []) as GridMeasureDefn[];
        const updatedRowData = convertPivotItemsToRowData(
          this.props.overTimeData,
          gridView,
          undefined,
          undefined,
          leafDataLevel,
          undefined,
          this.props.gridDefn.main?.displayTitle
        );

        this.setState({
          rowData: updatedRowData,
        });
      }
    }

    if (!isEqual(prevProps.editable, this.props.editable) && this.gridApi) {
      this.gridApi.refreshCells({ force: true });
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;

    if (this.props.onCleanup) {
      this.props.onCleanup();
    }
  }

  getDiff(): GranularEditPayloadItem[] {
    const before = this.state.rowDataOld;
    const after = this.state.rowData;
    if (before.length !== after.length) {
      throw new Error('Size Mismatch for diff');
    }
    const ln = before.length;
    const weeks = _.flatMap(this.props.scopeTimeEntries, (month) => month.children);

    const groups = {};
    const includedMeasures: {
      [groupId: string]: {
        [week: string]: {
          [k: string]: string | number | boolean | null;
        };
      };
    } = {};
    weeks.forEach((week) => {
      for (let i = 0; i < ln; ++i) {
        const rowBefore = before[i];
        const rowAfter = after[i];

        // Skip things not at level 2
        if (rowBefore.path.length !== 2) {
          continue;
        }

        if (rowBefore.groupId !== rowAfter.groupId) {
          throw new Error('Data Key Mismatch');
        }
        const measureId = rowBefore.measureId;
        if (measureId !== rowAfter.measureId) {
          throw new Error('Data Key Mismatch');
        }

        // can't work with undefined measures
        if (!measureId) {
          continue;
        }

        const d1 = rowBefore.extraData[week.id];
        const d2 = rowAfter.extraData[week.id];

        // flag for measures always included in updates (does not trigger updates on its own)
        if (rowAfter.includeInAllUpdates) {
          set(includedMeasures, `["${rowBefore.groupId}"]["${week.id}"]["${measureId}"]`, d2 || null);
          continue;
        }
        // same data - do nothing
        if (d1 === d2) {
          continue;
        }

        let groupItem = groups[rowBefore.groupId];
        if (!groupItem) {
          groupItem = groups[rowBefore.groupId] = {};
        }

        let weekItem = groupItem[week.id];
        if (!weekItem) {
          weekItem = groupItem[week.id] = {};
        }

        let value: string | number | boolean | null = rowAfter.extraData[week.id];
        if (typeof value === 'undefined' || value === '') {
          value = null;
        }
        weekItem[measureId] = value;
      }
    });
    const items: GranularEditPayloadItem[] = [];
    // need to reshape data here
    Object.keys(groups).forEach((groupId) => {
      const group = groups[groupId];
      Object.keys(group).forEach((weekId) => {
        const weekItem = {
          coordinates: {
            product: groupId,
            time: weekId,
          },
          ...group[weekId],
        };
        items.push(weekItem);
      });
    });
    return items;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.updateColumnDefs();
  }

  scrollAnchorIntoView(gridApi: GridApi, columnApi: ColumnApi) {
    const anchorField = columnApi.getColumn(this.state.anchorField);

    if (isNil(anchorField)) {
      return;
    }

    // find index of anchor field to determine next predetermined number of columns to ensureColumnVisible on
    const allColumns = columnApi.getAllColumns();
    const anchorCol = allColumns.find((column) => column.getColId() === this.state.anchorField);

    if (anchorCol) gridApi.ensureColumnVisible(allColumns[allColumns.length - 1]);
    if (anchorCol) gridApi.ensureColumnVisible(anchorCol);
  }

  publishItems = (columnId: string, rowNode: RowNode, isPublished: boolean) => {
    const update = {
      coordinates: {
        product: rowNode.data.groupId, // id has dataIndex appended, groupId is actual id value
        time: columnId,
      },
      [IS_PUBLISHED]: isPublished ? 0 : 1, // if already published, send 0 to de-publish
    };

    if (this.props.onValueChange) {
      this.props.onValueChange([update]);
    } else if (this.props.submitPayload) {
      this.props.submitPayload([update], false);
    }
  };

  mapItemToColDef = (item: ItemToColDef, classList: string[]) => {
    return {
      headerName: item.description || item.id,
      field: item.id,
      cellClass: () => classList,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.data && params.data.inputType && BLOCK_ENTER_EDITORS.includes(params.data.inputType)) {
          if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
            return true;
          }
        }
        return false;
      },
      cellRendererSelector: (params: ICellRendererParams): ComponentSelectorResult => {
        const renderer: string = params.data.renderer;
        switch (renderer) {
          case 'tooltipRenderer':
            return {
              component: 'tooltipRenderer',
            };
          case 'publishRenderer':
            return {
              component: 'publishRenderer',
              params: {
                onPublish: this.publishItems,
              },
            };
          default:
            return {
              component: 'flowSheetCellRenderer',
            };
        }
      },
      valueGetter: (params: ValueGetterParams) => {
        const rowData = params.node.data;
        if (rowData && rowData.calculation) {
          return this.cellCalc(params.node, params.colDef, rowData.calculation);
        } else {
          return params.data.extraData[params.colDef!.field!];
        }
      },
    };
  };

  generateConfiguredTimeColDefs(): ColDef[] {
    const { overTimeData, gridDefn } = this.props;
    const [topLevel, leafLevel] = gridDefn?.timeLevels?.colDef || [];
    const [_topDataLevel, leafDataLevel] = gridDefn?.timeLevels?.data || [];

    // groupBy at top and leaf levels
    // then parse and retrieve level values for inserting into colDefs

    const topLevelsObj = groupBy(overTimeData, topLevel);
    const leafLevelsObj: Record<string, ItemToColDef[]> = reduce(
      topLevelsObj,
      (acc, leaves, topLevelKey) => {
        const groupedLeaves = groupBy(leaves, leafLevel);
        const remappedLeavesKeys: ItemToColDef[] = map(groupedLeaves, (leaves, leafKey: string) => {
          return {
            id: get(leaves, `[0][${leafDataLevel}]`, ''),
            description: leafKey,
          };
        });
        const filteredMappedLeavesKeys = remappedLeavesKeys.filter(
          (mappedLeaves: ItemToColDef) => mappedLeaves.description.slice(0, 4) === topLevelKey.slice(0, 4)
        );

        return {
          ...acc,
          [topLevelKey]: filteredMappedLeavesKeys,
        };
      },
      {}
    );

    const finalColDefs = map(leafLevelsObj, (leafKeys, key: string) => {
      // build children ColDefs first
      const leafColDefs = map(leafKeys, (leafKey, leafIndex, leafKeysArr) => {
        const classList = [`depth-${1}-item-${leafIndex}`];
        if (leafKey.id === this.props.anchorField) {
          classList.push('anchor-field');
        }
        if (leafIndex === 0) {
          classList.push(`depth-${1}-first`);
        }
        if (leafIndex === leafKeysArr.length - 1) {
          classList.push(`depth-${1}-last`);
        }

        return this.mapItemToColDef(
          {
            description: leafKey.description,
            id: leafKey.id,
          },
          classList
        );
      });

      // then build parent ColDef
      const topLevelColDef = this.mapItemToColDef(
        {
          description: key,
          id: key,
        },
        []
      );

      return {
        ...topLevelColDef,
        marryChildren: true,
        children: leafColDefs,
      };
    });

    return sortBy(finalColDefs, ['field']);
  }

  decorateWithStyleClass(items: TimeEntry[], depth = 0, anchorField: string): ColDef[] {
    const firstIndex = 0;
    const lastIndex = items.length - 1;
    const newItems: ColDef[] = items.map((item, currentIndex) => {
      const classList = [`depth-${depth}-item-${currentIndex}`];
      if (item.id === anchorField) {
        classList.push('anchor-field');
      }
      if (firstIndex === currentIndex) {
        classList.push(`depth-${depth}-first`);
      }
      if (lastIndex === currentIndex) {
        classList.push(`depth-${depth}-last`);
      }
      return this.mapItemToColDef(item, classList);
    });

    items.forEach((item: TimeEntry, i) => {
      if (item.children && item.children.length) {
        const colDef = newItems[i] as ColGroupDef;
        colDef.marryChildren = true;
        colDef.children = this.decorateWithStyleClass(item.children, depth + 1, anchorField);
      }
    });

    return newItems;
  }

  cellEditorSelector = (params: ICellEditorParams): ComponentSelectorResult => {
    const colDef = params.colDef;
    const rowNode = params.node;
    if (params.data.measureId && colDef) {
      if (params.data.measureId === OVERRIDE_VRP_INDEX || params.data.renderer === 'checkbox') {
        return (null as unknown) as ComponentSelectorResult;
      }

      const data = params.data as RowData;

      switch (data.inputType) {
        case 'receiptsAdjCalculator':
          const isEditable = this.getEditable(rowNode, colDef);
          return {
            component: 'receiptsAdjCalculator',
            params: {
              isEditable,
              dataApi: getRecAdjDataApi(params.data.groupId, colDef.field),
              configApi: TEMP_REC_ADJ_CONFIG_API,
              floorset: colDef.field,
            },
          };
        case 'integer': {
          const percent = params.data.renderer === 'percent';
          const inputParams = data.inputParams;
          return {
            component: 'integerEditor',
            params: {
              passedInt: params.data.extraData[colDef.field!],
              inputParams: { ...inputParams, percent },
              regularPosition: true,
            },
          };
        }
        case 'textValidator': {
          const inputParams = data.inputParams;
          const whitelist = inputParams && viewDefnWhitelistToNarrowedCharacterWhitelist(inputParams.whitelist);

          return {
            component: 'textValidationEditor',
            params: {
              validateAsync: false,
              ...inputParams,
              whitelist,
            },
          };
        }
        case 'validValues': {
          const inputParams = data.inputParams;
          if (inputParams != null) {
            const {
              multiSelect,
              asCsv,
              postArrayAsString,
              allowEmptyOption,
              returnSelectionObject,
              ignoreCache,
              concatOptionValues,
              dataApi,
              includeCurrent,
            } = inputParams;
            const dataQa = isNil(multiSelect) ? 'select-configurable-grid' : 'select-multi-configurable-grid';
            // does NOT support params for dataApi, row data is a complete disaster in this screen
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: dataApi,
                dataQa,
                multiSelect,
                asCsv: asCsv,
                postArrayAsString: postArrayAsString,
                allowEmptyOption,
                returnSelectionObject,
                ignoreCache: ignoreCache,
                includeCurrent: includeCurrent,
                concatOptionValues: concatOptionValues,
              },
            };
          }
          break;
        }
        default:
      }

      return {
        component: 'agTextCellEditor',
      };
    }

    return (null as unknown) as ComponentSelectorResult;
  };

  isGroupEditable(params: ICellEditorParams): boolean {
    const data = params.data;
    return data.path.length === 1;
  }

  cellCalc = (node: RowNode, col: ColDef, calcStr: string) => {
    // get group id to fetch siblings
    const choiceId = node.data.groupId;
    const week = col.field || '';
    const getDataFromKey = (dataIndex: string) => {
      const rowNode = this.gridApi.getRowNode(`${choiceId}_${dataIndex}`);
      if (rowNode != null) {
        // row node found, field is either present or null
        const weekData = coalesce(this.gridApi.getValue(week, rowNode), null);
        return {
          rowNodeFound: true,
          data: weekData,
        };
      } else {
        return {
          rowNodeFound: false,
          data: null,
        };
      }
    };
    return executeCalculation(this.math, calcStr as string, getDataFromKey);
  };

  isEditable = (params: IsColumnFuncParams): boolean => {
    const data = params.data;
    const colDef = params.colDef;

    if (isNil(data) || isNil(colDef) || params.api == null) {
      return false;
    }

    const field = colDef.field || '';
    const isLockedNode = params.api.getRowNode(getId(data.groupId, LOCKED_INDEX));
    const isLockedData: RowData = isLockedNode.data;
    const isLocked = !!isLockedData.extraData[field] && !data.ignoreLock; // week/column isLocked

    const isPublishedNode = params.api.getRowNode(getId(data.groupId, PUBLISH_INDEX));
    const isPublishedData: RowData = isPublishedNode.data;
    const isPublished = !!isPublishedData.extraData[field] && !data.ignorePublished; // week/column isLocked
    const editableByCalc = data.editableByCalc;
    let isEditable = !!data.extraData[field + '_editable'] && this.props.editable && data.renderer != 'checkbox';
    if (editableByCalc != null) {
      isEditable = isEditable && this.cellCalc(params.node, params.colDef, editableByCalc);
    } else {
      isEditable = isEditable && !!data.editable;
    }
    const canEdit = data.path.length > 0 && !!data.measureId && isEditable;

    // allow user override editors to be editable for viewing when locked or published
    return data.inputType === 'receiptsAdjCalculator' ? canEdit : canEdit && !isLocked && !isPublished;
  };

  updateCell(groupId: string, measureId: string, field: string, newValue: string | number | boolean) {
    const rowData = this.state.rowData;
    if (measureId && groupId) {
      const comparator = (tmp: RowData) => tmp.groupId === groupId && tmp.measureId === measureId;
      const index = _.findIndex(rowData, comparator);
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = newValue;
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;
        this.setState({ rowData: newRowData, rowDataOld: this.state.rowData }, () => {
          this.gridApi.updateRowData({
            update: [measureData],
          });
          this.handleValueChange();
          const column = this.columnApi.getColumn(field);

          this.gridApi.refreshCells({
            force: true,
            columns: [column],
          });
        });
      }
    }
  }

  refreshField(field: string) {
    const column = this.columnApi.getColumn(field);

    this.gridApi.refreshCells({
      force: true,
      columns: [column],
    });
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    const data = event.data;
    const rowData = this.state.rowData;
    const field = event.colDef.field || '';

    const measureId = event.data.measureId;
    if ((data[field] && measureId === DC_USERADJ) || measureId === DC_FINREV) {
      if (rowData && data.groupId) {
        const { onOrderRevision, userAdjRevision } = data[field] || { onOrderRevision: 0, userAdjRevision: 0 };
        const newRowData = rowData.slice(0);
        const updates: RowData[] = [];
        if (onOrderRevision !== undefined) {
          const path = [data.groupId, DC_FINREV];
          const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, path));
          if (index >= 0) {
            const measureData = _.cloneDeep(rowData[index]);
            measureData.extraData[field] = onOrderRevision;
            newRowData[index] = measureData;
            updates.push(measureData);
          }
        }
        // updated userAdjRevision
        if (userAdjRevision !== undefined) {
          const path = [data.groupId, DC_USERADJ];
          const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, path));
          if (index >= 0) {
            const measureData = _.cloneDeep(rowData[index]);
            measureData.extraData[field] = userAdjRevision;
            newRowData[index] = measureData;
            updates.push(measureData);
            // Update Valid receipt week as well
            const rcptIndex = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, [data.groupId, 'dc_uservrp']));
            const validRcptData = _.cloneDeep(rowData[rcptIndex]);
            const sysvrp = _.get(validRcptData, ['extraData', `${field}_dependentData`, SYS_VRP_INDEX]);
            // FIXME: This weirdness is due to how the dc_uservrp is handled. It is a reverse toggle of whatever sys_vrp is.
            // No...I don't know why.
            if (sysvrp === 1) {
              // we only want to toggle *off* uservrp IFF revision is 0, null does not cause the override to invalid toggle
              // that check may be unnecessary as all zeroes results in null, but it's a failsafe.
              validRcptData.extraData[field] = userAdjRevision === 0 ? 1 : 0;
            } else {
              validRcptData.extraData[field] = userAdjRevision > 0 ? 1 : 0;
            }
            newRowData[rcptIndex] = validRcptData;
            updates.push(validRcptData);
          }
        }
        this.setState({ rowData: newRowData, rowDataOld: this.state.rowData }, () => {
          this.gridApi.updateRowData({
            update: updates,
          });
          this.handleValueChange();
          this.refreshField(field);
        });
      }
      return;
    }

    if (rowData && data.measureId && data.groupId) {
      const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, data.path));
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = data[field];
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;

        this.setState({ rowData: newRowData, rowDataOld: this.state.rowData }, () => {
          this.gridApi.updateRowData({
            update: [measureData],
          });
          this.handleValueChange();
          this.refreshField(field);
        });
      }
    }
  }

  handleValueChange = () => {
    const diff = this.getDiff();
    if (!diff.length) {
      return;
    }

    const valueChangeHandler = this.props.onValueChange || this.props.submitPayload;

    if (valueChangeHandler) {
      valueChangeHandler(diff, false);
    }
  };

  doesExternalFilterPass(node: RowNode): boolean {
    return !node.data.hidden;
  }

  onBodyScroll = (event: BodyScrollEvent) => {
    const editingCells = this.gridApi.getEditingCells();
    if (editingCells.length > 0) {
      const editingCell = editingCells[0];
      const editingRowNode = this.gridApi.getDisplayedRowAtIndex(editingCell.rowIndex);

      const headerRow = document.querySelector('.ag-header') as HTMLElement;
      const headerRowHeight = parseFloat((headerRow.style.height || '112px').replace('px', ''));
      const popupEditor = document.querySelector('.ag-popup-editor') as HTMLElement;
      if (popupEditor) {
        const calculatedPopupTop = editingRowNode.rowTop + headerRowHeight - event.top;
        const calculatedPopupLeft = editingCell.column.getLeft() - event.left;

        popupEditor.style.left = `${calculatedPopupLeft}px`;
        popupEditor.style.top = `${calculatedPopupTop}px`;
      }
    }
  };

  getColumnMenuItems = (params: GetMainMenuItemsParams) => {
    return params.defaultItems.filter((item) => item !== 'autoSizeAll');
  };

  renderPopover = (params: { id: string | undefined; parentId: string | undefined }) => {
    const { onItemClicked } = this.props;
    if (onItemClicked) {
      onItemClicked(params);
    }
  };

  getEditable = (rowNode: RowNode, colDef: ColDef): boolean => {
    let isEditable = false;

    // the logic here relies on a lot of assumptions about the data shape,
    // that fail rapidly in the face unusual data, or when the grid is refreshing, hydrating, or in the process of being unmounted
    // We wrap the whole thing in a try here as a final guard against those unexpected failure modes, INT-2803
    try {
      const editableByCalc = rowNode.data.editableByCalc;
      const week = colDef.field || '';
      const measureId = rowNode.data.measureId;

      const isLockedNode = this.gridApi.getRowNode(getId(rowNode.data.groupId, LOCKED_INDEX));
      const isLockedData: RowData = isLockedNode.data;
      const isLockedIgnored = rowNode.data.ignoreLock;
      const isLocked = !isLockedIgnored && !!isLockedData.extraData[week];

      const isPublishedNode = this.gridApi.getRowNode(getId(rowNode.data.groupId, PUBLISH_INDEX));
      const isPublishedData: RowData = isPublishedNode.data;
      const isPublishIgnored = rowNode.data.ignorePublished;
      const isPublished = isPublishedData.extraData[week] && !isPublishIgnored;

      if (editableByCalc != null) {
        isEditable = !isLocked && !isPublished && this.cellCalc(rowNode, colDef, editableByCalc) && this.props.editable;
      } else {
        const hardEditable = [TEST_PO, FLOORSET_PO, OVERRIDE_VRP_INDEX];
        if (hardEditable.indexOf(measureId) >= 0) {
          isEditable = !isLocked && !isPublished;
        } else if (measureId === LOCKED_INDEX) {
          isEditable = !isPublished;
        } else if (measureId === PUBLISH_INDEX) {
          const isPublishableNode = !!this.gridApi.getRowNode(getId(rowNode.data.groupId, IS_PUBLISHABLE_INDEX)).data
            .extraData[week];
          isEditable = isPublishableNode;
        } else {
          isEditable = !isLocked && !isPublished && rowNode.data.editable;
        }
      }
      isEditable = isEditable && this.props.editable && rowNode.data.extraData[`${week}_editable`];
    } catch (_error) {
      // don't log here, becuase it'll log once per row and spam the logging with unnecessary entries
      isEditable = false;
    }
    return isEditable;
  };

  getAdornments = () => {
    return this.props.adornments;
  };

  render() {
    const frameworkComponents = this.state.frameworkComponents as {
      [renderer: string]: { new (): Record<string, any> };
    };
    return (
      <React.Fragment>
        <ExtendedDataGrid
          className={classes(styles.dataGridStyle, AgGridHeaderBreakClass, 'double-header')}
          loaded={this.props.loaded}
          data={this.state.rowData}
          singleClickEdit={true}
          columnDefs={[]} // we interact directly with columnapi for this view
          frameworkComponents={frameworkComponents}
          onGridReady={this.onGridReady}
          onBodyScroll={this.onBodyScroll}
          rowHeight={this.props.rowHeight}
          exportOptions={{
            fileName: this.GRID_EXPORT_NAME,
            processCellOverride: ({ node, column }: ProcessCellForExportParams) => {
              if (node && node.parent) {
                // Special case because OVERRIDE_VRP_INDEX's value is stored in dependentData
                if (column.getId() !== 'ag-Grid-AutoColumn' && node.data.measureId === OVERRIDE_VRP_INDEX) {
                  const colId = column.getColId();
                  const dependentData = node.data.extraData[colId + '_dependentData'];
                  if (dependentData) {
                    const sysvrp = dependentData[SYS_VRP_INDEX];
                    const uservrp = node.data.extraData[colId];
                    if ((sysvrp === 1 && uservrp === 1) || (sysvrp === 0 && uservrp === 0)) {
                      return '0';
                    } else if (sysvrp === 1 || uservrp === 1) {
                      return '1';
                    } else {
                      return '';
                    }
                  }
                }

                if (node.parent.id === 'ROOT_NODE_ID' && column.getId() === 'ag-Grid-AutoColumn') {
                  const returnName: string = node.data.name;
                  const [description] = returnName.split(':');
                  // verify description is available and if not display error in cell text since data is not present
                  const descriptionFound = !isEmpty(description);
                  return descriptionFound ? returnName : `[ERROR: NO DESCRIPTION DATA] - ${returnName}`;
                }

                if (column.isPinnedLeft()) {
                  return node.data.name;
                }
              }
            },
          }}
          extraAgGridProps={{
            // 'excludeChildrenWhenTreeDataFiltering' makes children of groups have to go through the external filter call separately
            // see SUP-24
            excludeChildrenWhenTreeDataFiltering: true,
            defaultColDef: {
              editable: this.isEditable,
              cellEditorSelector: this.cellEditorSelector,
              cellStyle: {
                margin: 0,
              },
              cellRendererParams: {
                editable: this.getEditable,
              },
              width: this.props.columnWidth ? this.props.columnWidth : 130,
              lockPosition: true,
              resizable: true,
            },
            // using getRowHeight instead of just setting rowHeight, to support configurable group row height
            getRowHeight: (params: { data: any }) =>
              getGridRowHeight(params.data, this.props.rowHeight, this.props.groupRowHeight),
            headerHeight: FLOWSHEET_HEADER_HEIGHT,
            onCellValueChanged: this.onCellValueChanged,
            getRowNodeId: (data: RowData) => data.id,
            deltaRowDataMode: true,
            context: this.state.context,
            treeData: true,
            groupDefaultExpanded: 1,
            animateRows: true,
            getDataPath: (data: RowData) => data.path,
            suppressScrollOnNewData: true,
            stopEditingWhenGridLosesFocus: true,
            isExternalFilterPresent: () => true,
            doesExternalFilterPass: this.doesExternalFilterPass,
            getMainMenuItems: this.getColumnMenuItems,
            autoGroupColumnDef: {
              headerName: 'Name',
              width: 300,
              pinned: 'left',
              lockPinned: true,
              cellRendererParams: {
                suppressCount: true,
                innerRenderer: 'headerCellRenderer',
                getAdornments: this.getAdornments,
                onHeaderClick: this.renderPopover,
              },
              resizable: true,
            },
          }}
        />
      </React.Fragment>
    );
  }
}
