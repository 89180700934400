import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNil, isEmpty, findIndex, indexOf, filter, map, uniqBy, get } from 'lodash';
import serviceContainer from 'src/ServiceContainer';

import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetGrid';
import { ComponentTypeGridDispatchProps } from 'src/pages/AssortmentBuild/Pricing/PricingGrid';

import { FlowSheetGridProps } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { ASSORTMENT, ASSORTMENT_BUILD_FILTER_WARNING } from 'src/utils/Domain/Constants';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Pivot, TreePivot } from 'src/worker/pivotWorker.types';

import {
  fetchOverTimeData as fetchFlowSheetOverTimeData,
  receiveFlowSheetByStyleConfig,
  submitMassEditPayload as submitFlowSheetPayload,
  cleanUp as cleanupFlowsheet,
  fetchMassEditData,
  receiveSomeFlowSheetConfig,
  FlowSheetViewDefns,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.slice';
import { dispatchUpdateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import Subheader from 'src/components/Subheader/Subheader.container';
import { LegacySubheaderActionButtonProps } from 'src/components/Subheader/Subheader.types';
import { AutoSizer } from 'react-virtualized';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';
import { MassEditConfig, MassEditSelectItemData, MassEditSubmissionData } from 'src/components/MassEdit/MassEdit';
import { Action } from 'redux';
import math from 'mathjs';
import { executeCalculation } from 'src/utils/LibraryUtils/MathUtils';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { WRAPPER_SUBHEADER_HEIGHT } from 'src/pages/Worklist/Worklist.styles';
import { TabbedComponentWrapperOwnProps } from 'src/pages/Worklist/Worklist.types';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { DaysRangeListResponse } from 'src/types/Scope';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

type TabbedFlowSheetProps = FlowSheetGridProps & {
  subheaderSummary?: string;
  overTimeData?: TreePivot;
  massEditData: Pivot;
  allowFrom: string;
  allowTo: string;
  rangeList: DaysRangeListResponse;
  isMassEditDataLoaded: boolean;
  showFlowStatus: boolean;
  viewDefns: FlowSheetViewDefns;
} & Pick<TabbedComponentWrapperOwnProps, 'styles'>;

export function mapStateToPropsFlowSheet(
  state: AppState,
  ownProps: TabbedComponentWrapperOwnProps
): TabbedFlowSheetProps {
  const { pages, scope, worklist } = state;
  const viewState = pages.assortmentBuild.flowSheet;
  const scopeStart = !isNil(scope.scope.start) ? scope.scope.start : undefined;
  const { viewDefns } = viewState;
  const rangeList = scope.rangeList;
  const allowFrom = !isEmpty(rangeList) ? rangeList[0].id : '';
  const allowTo = !isEmpty(rangeList) ? rangeList[rangeList.length - 1].id : '';
  const isMassEditDataLoaded = isDataLoaded(viewState.viewDataStateMassEdit);
  const overtimeData = getUniqueDataFromCache(viewState, HashType.flowsheetOverTime)?.tree || [];
  const loaded = isDataLoaded(viewState.viewDataStateOverTime);
  const isLiveDataReady = viewState.viewDataStateOverTime === ViewDataState.liveDataReady;
  const massEditData = worklist.liveData;
  const groupRowHeight = viewState.viewDefns.grid.main?.groupRowHeight;
  const adornments: AdornmentType[] = get(viewState.viewDefns, 'grid.adornments', []);

  return {
    loaded,
    scopeTimeEntries: scope.timeInfo.entries,
    overTimeData: overtimeData,
    anchorField: scopeStart,
    rowHeight: viewDefns?.grid.main?.rowHeight,
    groupRowHeight,
    editable: isLiveDataReady,
    ...viewState,
    allowFrom,
    itemId: ownProps.selectedItemId || '',
    allowTo,
    rangeList: scope.daysRangeList,
    isMassEditDataLoaded,
    massEditData,
    showFlowStatus: ownProps.showFlowStatus === false ? false : true,
    styles: ownProps.styles,
    gridDefn: viewDefns.grid,
    adornments,
  };
}

export function dispatchToPropsFlowSheet(
  dispatch: AppThunkDispatch,
  ownProps: TabbedComponentWrapperOwnProps
): ComponentTypeGridDispatchProps {
  const { viewDefns } = ownProps;

  const dispatchHandlers = {
    onRenderGrid: async () => {
      const configResponse = await container.tenantConfigClient.getTenantViewDefnsWithFavorites({
        defnIds: ownProps.viewDefns,
        appName: ASSORTMENT,
      });
      // have to remap views to view property for access in grid data formatting function
      const { views, ...remainingObj } = configResponse[0];
      configResponse[0] = {
        ...remainingObj,
        view: !isNil(views) ? views : [],
      };
      const favoritesList = (configResponse as any)[viewDefns.length];
      const unmodifiedViewDefn: TenantConfigViewData = configResponse[0];
      const localConfig = getLocalConfig(
        viewDefns[0],
        (favoritesList as unknown) as FavoriteResponseItem[],
        dispatch,
        unmodifiedViewDefn
      );

      const configWithFavorites =
        isNil(localConfig) || isNil(localConfig.config) ? unmodifiedViewDefn : localConfig.config;
      const hasMassEdit = viewDefns.length >= 2;
      const maybeMassEdit = hasMassEdit ? configResponse[viewDefns.length - 1] : undefined;

      dispatch(
        receiveFlowSheetByStyleConfig({
          // lazy change, types are insane
          grid: configWithFavorites as any,
          listSort: {} as TenantConfigViewData,
          subheaderRollUp: {} as TenantConfigViewData,
          list: {} as TenantConfigViewData,
          unmodifiedViewDefn,
          massEdit:
            !isNil(maybeMassEdit) && !isEmpty(maybeMassEdit)
              ? ((maybeMassEdit as unknown) as MassEditConfig)
              : undefined,
        })
      );
      if (ownProps.massEditModel != null) {
        dispatch(fetchMassEditData(ownProps.massEditModel));
      }
      dispatch(fetchFlowSheetOverTimeData(ownProps.selectedItemId, ownProps.dataApi));
    },
    submitPayload: (payload: GranularEditPayloadItem[]) => {
      return dispatch(submitFlowSheetPayload(payload));
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveSomeFlowSheetConfig((config as unknown) as FlowSheetViewDefns));
    },
    onCleanup: () => {
      dispatch(cleanupFlowsheet());
    },
    onRefetchData: () => {
      dispatch(
        (_dispatch: AppThunkDispatch, _getState: () => AppState): Promise<Action | void> => {
          dispatch(fetchFlowSheetOverTimeData(ownProps.selectedItemId, ownProps.dataApi));
          if (ownProps.massEditModel != null) {
            dispatch(fetchMassEditData(ownProps.massEditModel));
          }
          return Promise.resolve();
        }
      );
    },
  };

  return dispatchHandlers;
}

const FlowSheetGridWithSubheader = (props: TabbedFlowSheetProps) => {
  const {
    allowFrom,
    allowTo,
    anchorField,
    rangeList,
    isMassEditDataLoaded,
    viewDefns,
    showFlowStatus,
    showUndoBtn,
    subheaderSummary,
  } = props;

  const handleMassEditSubmission = async (submission: MassEditSubmissionData) => {
    const { selectedModifier, selectedItems, selectedWeekList, modifierValue } = submission;

    if (!isNil(selectedModifier) && !isNil(selectedWeekList) && !isNil(viewDefns) && !isNil(viewDefns.massEdit)) {
      const massEditConf = viewDefns.massEdit;
      const selectedModConf = massEditConf.views[0].modifierTypes.find(
        (modType) => modType.dataIndex === selectedModifier
      );
      // no null submission data expected, except for modifier value

      const dataPiv = await serviceContainer.pivotService.getFlowSheetByIndex(
        selectedItems.map((i) => i.value),
        selectedWeekList[0],
        selectedWeekList[selectedWeekList.length - 1]
      );
      const data = dataPiv.tree;
      const filteredItems = filter(data, (item) => {
        return findIndex(selectedItems, ['value', item.id]) >= 0 && indexOf(selectedWeekList, item.time) >= 0;
      });
      const updatedWeekData = map(filteredItems, (item) => {
        if (selectedModConf && selectedModConf.calculation) {
          const newValue = executeCalculation(math, selectedModConf.calculation, (key) => {
            return {
              rowNodeFound: true,
              data: key === '$editValue' ? modifierValue : get(item, key, null),
            };
          });
          return {
            coordinates: {
              product: item.id,
              time: item.time,
              location: item.channel,
            },
            [selectedModifier]: newValue,
          };
        } else {
          return {
            coordinates: {
              product: item.id,
              time: item.time,
              location: item.channel,
            },
            [selectedModifier]: modifierValue,
          };
        }
      });

      if (props.submitPayload) {
        props.submitPayload(updatedWeekData, true);
      }
      if (props.onRefetchData) {
        props.onRefetchData();
      }
    }
  };
  const getEditableItems = (): (MassEditSelectItemData & { time: string; weekadjslsu: number })[] | undefined => {
    const { viewDefns, massEditData } = props;

    const { massEdit: massEditConfig } = viewDefns!;
    const itemsDataIndex = !isNil(massEditConfig) ? massEditConfig.views[1].dataIndex : '';
    const editableItems =
      massEditData &&
      massEditData.tree.map((dataItem) => {
        const label: any = `${dataItem['name']}-${dataItem[itemsDataIndex]}`;
        const weekadjslsu = dataItem['a_unc_sls_u'];
        return {
          value: dataItem['id'],
          label,
          weekadjslsu,
          time: dataItem['time'],
        };
      });

    return editableItems;
  };

  const onUpdateConfig = (config: TenantConfigViewData) => {
    props.onUpdateConfig && props.onUpdateConfig(config);
  };

  const generateExtraActionButtonProps = (): LegacySubheaderActionButtonProps => {
    const massEdit = isNil(viewDefns.massEdit)
      ? undefined
      : {
          config: viewDefns.massEdit,
          allowFrom,
          allowTo,
          scopeStart: anchorField || '',
          rangeList,
          editableItems: uniqBy(getEditableItems(), 'value'),
          title: 'Mass Copy Flowsheet Data',
          handleSubmit: handleMassEditSubmission,
          dataLoading: !isMassEditDataLoaded,
        };

    return {
      massEdit,
    };
  };

  const viewConfigurator = viewDefns &&
    viewDefns.unmodifiedViewDefn && {
      viewConfig: viewDefns.grid,
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
      showPinCheckboxForGrid: false,
      updateConfig: onUpdateConfig,
    };

  const extraActionButtons = generateExtraActionButtonProps();
  return (
    <React.Fragment>
      <AutoSizer style={{ height: `100%`, width: '100%' }}>
        {({ height }) => {
          return (
            <React.Fragment>
              <Subheader
                title={''}
                errorCondition={ASSORTMENT_BUILD_FILTER_WARNING}
                // search is rendered in the companion view already
                showSearch={false}
                showFlowStatus={showFlowStatus}
                summary={subheaderSummary}
                // @ts-ignore
                viewConfigurator={viewConfigurator}
                extraLegacyActionButtons={extraActionButtons}
                showUndoBtn={showUndoBtn}
              />
              {/* Height needs to be explicit for grid.  */}
              <div style={{ height: height - WRAPPER_SUBHEADER_HEIGHT }}>
                <FlowSheetGrid {...props} />
              </div>
            </React.Fragment>
          );
        }}
      </AutoSizer>
    </React.Fragment>
  );
};

export const TabbedFlowSheetGrid = connect(
  mapStateToPropsFlowSheet,
  dispatchToPropsFlowSheet
  // @ts-ignore
)(makeScopeAndFilterSensitive(makePopoverSensitive(FlowSheetGridWithSubheader)));
