import { CellClickedEvent, GridApi, GridReadyEvent, ColumnApi, RowSelectedEvent } from 'ag-grid-community';

import { CompanionListView } from 'src/common-ui/index';
import { Props as CompanionProps, ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import {
  mapValues,
  findIndex,
  isNil,
  parseInt,
  isEmpty,
  isEqual,
  omitBy,
  isBoolean,
  noop,
  concat,
  set,
  intersection,
  debounce,
} from 'lodash';
import React from 'react';
import { resolvePath } from 'src/cdn';

import { Overlay } from 'src/common-ui/index';
import {
  companionStyles,
  styles,
  extraRowContainerStyles,
  listPairStyle,
  gridContainerStyle,
  macrosContainer,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import {
  ConfigurableGridState,
  ConfigurableGridProps,
  MassColumnUpdateParams,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';

import { isNumber } from 'lodash/fp';
import { RowNode } from 'ag-grid-community';
import Subheader from 'src/components/Subheader/Subheader.container';
import { createMassEditButton } from 'src/components/Subheader/Subheader';
import { SubheaderOwnProps, SubheaderActionButtonProps } from 'src/components/Subheader/Subheader.types';
import { MenuItemDef } from 'ag-grid-community';

import ArrowValueRenderer, {
  ArrowValueRendererProps,
  ARROWDIRECTIONS,
} from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import ServiceContainer from 'src/ServiceContainer';

import { simpleByField } from 'src/utils/Pivot/Sort';
import Renderer from 'src/utils/Domain/Renderer';
import { toast } from 'react-toastify';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { BasicItem as PivotBasicItem } from 'src/worker/pivotWorker.types';
import { logError } from 'src/services/loggingService';
import { FabType } from '../higherOrder/withFab';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { makeValidValuesCache } from 'src/services/validValuesCache';
import { getWorklistMultiselectMenuItems } from '../WorklistContextMenu/WorklistContextMenu';
import { TopAttribute } from 'src/services/configuration/codecs/viewdefns/general';
import ConfigureModal, { ConfigureModalProps, Option } from '../Configure/ConfigureModal';
import { EditableGrid, EditableGridProps } from './EditableGrid/EditableGrid';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import { formatConfigurableActionItem } from './utils/ConfigurableGrid.utils';
import MetricLineGraph from 'src/components/Visualize/MetricLineGraph/MetricLineGraph';
import { MassEdit as MassEditModal } from 'src/components/MassEdit/MassEditv2';
import { ConfigurablePostAction } from './utils/ConfigurablePostActions';
const noImage = resolvePath(noImagePath);

export class ConfigurableGrid extends React.Component<ConfigurableGridProps, ConfigurableGridState> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  selectPopupLeft!: number;
  selectPopupTop!: number;
  allowEnterList: (string | undefined)[] = [];

  constructor(props: ConfigurableGridProps) {
    super(props);

    this.state = {
      gridRdyCnt: 0,
      companionCollapsed: false,
      companionSortDirection: 'desc',
      companionSortField: undefined,
      activeStyleColor: '',
      notificationModal: false,
      actionType: undefined,
      selectedItems: [],
      validValuesCache: makeValidValuesCache(),
      massEditModal: false,
    };
  }

  componentDidUpdate(prevProps: ConfigurableGridProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.state.validValuesCache.clear();
    }

    if (!isEqual(prevProps.favoritesList, this.props.favoritesList)) {
      const activeFavorite = this.props.favoritesList.find((x) => x.active === true);
      if (activeFavorite && activeFavorite.jsonBlob) {
        if (activeFavorite.jsonBlob.companionData) {
          const compData = activeFavorite.jsonBlob.companionData;
          this.setState({
            companionSortField: compData.companionSortField,
            companionSortDirection: compData.companionSortDirection,
            companionCollapsed: compData.companionCollapsed,
          });
        }
        if (activeFavorite.jsonBlob.groupBySelection && this.props.groupByDropdownProps) {
          const nextSelectedIndex = activeFavorite.jsonBlob.groupBySelection || 0;
          const dropdownData: ConfigurableGridGroupBySelection = {
            selectedIndex: nextSelectedIndex,
            option: this.props.groupByDropdownProps.options[nextSelectedIndex],
          };

          const selectedIndex = this.props.groupByDropdownProps?.selection;
          if (nextSelectedIndex !== selectedIndex) {
            this.props.setGroupBySelection(dropdownData);
          }
        }
      }
    }
  }

  onUpdateConfig = (config: any) => {
    if (config.isDefault) {
      this.setState({
        companionSortDirection: 'desc' as const,
        companionCollapsed: false,
        companionSortField: this.props.defaultCompanionSortField,
      });
    }

    this.props.onUpdateConfig(config);

    if (!isNil(this.props.groupByDropdownProps)) {
      this.props.setGroupBySelection({
        selectedIndex: config.groupBySelection,
        option: this.props.groupByDropdownProps.options[config.groupBySelection],
      });
    }
  };

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  generateCompanionViewData = (data: BasicPivotItem[]) => {
    const { identifier } = this.props;
    const companionSortField =
      (this.state.companionSortField ? this.state.companionSortField : this.props.defaultCompanionSortField) || '';
    const companionSortDirection = this.state.companionSortDirection;
    const removedGroupData = data.filter((dat) => {
      // Groups only have one attribute and one meta property in the object: group and id and gridasyncstate
      return Object.keys(dat).length > 3;
    });

    const sortedData = simpleByField(removedGroupData, companionSortField, companionSortDirection);
    const compData = sortedData.map((d) => {
      const name = d[`member:${identifier}:name`] ? d[`member:${identifier}:name`] : d.name;
      const description = d[`member:${identifier}:description`] ? d[`member:${identifier}:description`] : d.description;
      return {
        id: name,
        name: description,
        'member:style:id': d['member:style:id'],
        'member:style:name': d['member:style:name'],
        'member:stylecolor:id': d['member:stylecolor:id'],
        'member:stylecolor:name': d['member:stylecolor:name'],
        stars: d['compositeattributeband'] ? parseInt(d['compositeattributeband'], 10) : 0,
        imageUri: d['attribute:img:id'],
      };
    });

    return compData;
  };

  renderCompanionView = (data: BasicPivotItem[]) => {
    const { identifier, defaultCompanionSortField } = this.props;
    const { selectedIndex, companionScrollTo } = this.state;
    const compData = this.generateCompanionViewData(data);
    const sortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === this.state.companionSortField;
    });
    const defaultSortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === defaultCompanionSortField;
    });

    if (compData) {
      const companionProps: CompanionProps = {
        defaultSelection: sortSelection > -1 ? sortSelection : defaultSortSelection,
        sortOptions: this.props.companionSortOptions,
        label: 'Count',
        selectedIndex,
        className: companionStyles,
        data: compData,
        noImageUrl: noImage,
        scrollTo: companionScrollTo,
        initialSortDirection: this.state.companionSortDirection,
        isCollapsed: this.state.companionCollapsed,
        onListItemClicked: (identityValue: string) => {
          // TODO: make this logic reusable so it can be invoked during handlePendingCellUpdate if needed
          function findIndexComp(dataKey: string) {
            return compData.findIndex((datum) => {
              const fieldFound = datum[dataKey] ? datum[dataKey] : datum.name;
              return fieldFound === identityValue;
            });
          }
          let key = identifier === 'id' ? 'id' : `member:${identifier}:id`;
          let index = findIndexComp(key);

          key = identifier === 'id' ? 'name' : `member:${identifier}:name`;
          index = index === -1 ? findIndexComp(key) : index;

          const newState = {
            gridScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
            selectedId: compData[index].id,
          };
          this.setState(newState);
        },
        onChangeDirection: (direction) => {
          this.setState({
            companionSortDirection: direction,
          });
        },
        onSortSelection: (selection) => {
          this.setState({
            companionSortField: selection.dataIndex,
          });
        },
        onToggleCollapse: (isCollapsed) => {
          this.setState({ companionCollapsed: isCollapsed });
        },
      };
      return <CompanionListView {...companionProps} />;
    } else {
      return <div />;
    }
  };

  handleChangeGroupByDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { groupByDropdownProps, setGroupBySelection } = this.props;
    if (isNil(groupByDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(groupByDropdownProps.options, (option) => {
      return newValue !== null && option.text.substr(0, 16) === newValue.substr(0, 16);
    });
    setGroupBySelection({
      selectedIndex: valueIndex,
      option: groupByDropdownProps.options[valueIndex],
    });
    // TODO move this down
    // if (this.gridApi) {
    //   this.gridApi.deselectAll();
    // }
  };

  handleChangeFloorsetDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { floorsetDropdownProps, setFloorsetSelection } = this.props;
    if (isNil(floorsetDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(floorsetDropdownProps.options, (option) => {
      return option.text === newValue;
    });
    const selection = floorsetDropdownProps.options[valueIndex];
    setFloorsetSelection(selection.text);
  };

  generatePublishedText = (data: BasicPivotItem | undefined, configuratorViewDefn: any) => {
    if (isNil(data) || isNil(configuratorViewDefn)) {
      return [];
    }
    let publishText: string[] = [];
    if (configuratorViewDefn.topAttributes) {
      publishText = configuratorViewDefn.topAttributes.left.map((config: TopAttribute) => {
        const mask = config.mask;
        const renderer = Renderer[config.renderer];
        // We do this to prevent screen from crashing when fields are missing from data
        try {
          const value = renderer && mask ? Renderer[config.renderer](data, mask) : '';
          return value;
        } catch (e) {
          console.error(`Error building template for left text. \n ${e}`);
          return '';
        }
      });
    }

    return publishText;
  };

  getArrowFromValue = (value: number) => {
    // Math.sign return 1 for positive, 0 for 0, and -1 for negative
    switch (Math.sign(value)) {
      case 1:
      case 0:
        return ARROWDIRECTIONS.UP;
      case -1:
      default:
        return ARROWDIRECTIONS.DOWN;
    }
  };

  getActiveFloorset = (): string => {
    const { floorsetDropdownProps } = this.props;

    if (isNil(floorsetDropdownProps)) {
      return '';
    }

    const selection = floorsetDropdownProps.selection || 0;
    return floorsetDropdownProps.options[selection].dataIndex;
  };

  getSelectedRows = (): PivotBasicItem[] => {
    if (this.gridApi == null) return [];
    const selectedNodes: RowNode[] = this.gridApi.getSelectedNodes();
    const nodesAfterFilter: RowNode[] = [];
    this.gridApi.forEachNodeAfterFilter((n) => {
      nodesAfterFilter.push(n);
    });

    // Filter the selected nodes based on column filters in the filter model
    const nodesIntersection = intersection(selectedNodes, nodesAfterFilter);

    const floorsetId = this.getActiveFloorset();
    return nodesIntersection
      .filter((n) => {
        return isNil(n.allChildrenCount) || n.allChildrenCount <= 0;
      })
      .map((n) => {
        const rowData = n.data;
        rowData.floorset = floorsetId;
        return rowData;
      });
  };

  submitGlobalUpdate = async ({ dataIndex, value }: Omit<MassColumnUpdateParams, 'value'> & { value: unknown }) => {
    // This fun mess removes that silly wrapped colon stuff (eg: attribute:<x>:id)
    // This is the more concise version: .replace(/(?:^.*?:)?([^:]*)(?::.*)?/, '$1')
    const key = dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    // TODO: may need to devise a more configurable way for setting up the coordinateMap
    // when not reliant on specific view items
    const coordinates = [
      {
        product: this.props.scopeSelection.productMember || '',
        location: this.props.scopeSelection.locationMember || '',
      },
    ];
    const payload: CoarseEditPayload = {
      coordinates,
      [dataIndex]: value,
    };

    let calcValue = value;
    if (isBoolean(value)) {
      calcValue = value ? 'true' : '';
    }
    payload[key] = calcValue;
    return await ServiceContainer.pivotService.coarseEditSubmitData(payload);
    // await fetch new value set & merge
  };

  publishAssortment = async (actionType?: 'publish' | 'unpublish') => {
    let coordinates = [];
    const configedColumn = this.props.columnDefs.find((col) => {
      return col.dataIndex === 'dc_publish';
    });
    if (!actionType) return;
    const selectedItems = this.getSelectedRows();

    if (isEmpty(selectedItems)) {
      return;
    }
    let coordMap: {
      [s: string]: string;
    };
    if (this.props.updateCoordinateMap != null) {
      coordMap = this.props.updateCoordinateMap;
    } else if (this.props.massEditConfig != null) {
      coordMap = this.props.massEditConfig.coordinateMap;
    } else {
      logError(`Cannot update dc_publish. Somehow set to generic update without updateCoordinateMap property.`, null);
      return;
    }
    coordinates = selectedItems.map((rowData) =>
      omitBy(
        mapValues(coordMap, (v) => {
          const value = rowData[v];
          return value;
        }),
        isNil
      )
    );

    if (this.props.submitPayload) {
      const payload = {
        coordinates,
        ['dc_publish']: actionType === 'publish' ? 1 : 0,
      };
      await this.props.submitPayload(payload).then(() => {
        this.setState({
          notificationModal: false,
        });
        this.gridApi.deselectAll();
      });
    }
  };

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.state.notificationModal}
        descriptionText={`Are you sure you wish to ${this.state.actionType}?`}
        onConfirm={async () => {
          try {
            await this.publishAssortment(this.state.actionType);
          } catch (e) {
            toast.error('Failed To Publish');
            ServiceContainer.loggingService.error('Failed To Publish');
          }
        }}
        onCancel={() => {
          this.gridApi.deselectAll();
          this.setState({
            notificationModal: false,
          });
        }}
      />
    );
  };
  renderMassEditModal = () => {
    const { massEditConfig } = this.props;
    const massEdit = isNil(massEditConfig)
      ? undefined
      : {
          config: massEditConfig,
          title: massEditConfig.title,
          handleSubmit: () => {
            this.gridApi?.deselectAll();
            this.props.onRefreshConfigurableGridData();
          },
          getSelectedItems: () => this.getSelectedRows(),
          handleCancel: () => this.gridApi?.deselectAll(),
          gridApi: this.gridApi,
          dataLoading: this.state.gridRdyCnt === 0,
          isOpen: this.state.massEditModal,
          onModalToggle: (isOpen: boolean) => {
            this.setState({
              massEditModal: isOpen,
            });
          },
          onSelectedItems: (selectedItems: PivotBasicItem[]) => {
            this.setState({
              selectedItems,
            });
          },
        };
    const massEditModal = massEdit ? <MassEditModal {...massEdit} /> : null;
    return massEditModal;
  };

  handleConfigurablePostAction = (updateType: 'coarse' | 'granular', value: unknown, dataIndex: string) => {
    // for now, assuming will always be department (not location) and coarse (not granular)?

    const update = {
      nodes: (this.getSelectedRows() as unknown) as RowNode[],
      dataIndex,
      value,
    };

    this.submitGlobalUpdate(update)
      .catch((error) => {
        toast.error(`${(error as any).message}`);
      })
      .finally(() => {
        this.props.onRefreshConfigurableGridData();
      });
  };

  generateSubheaderExtraDropdownProps(): SubheaderDropdownProps[] {
    const { groupByDropdownProps, floorsetDropdownProps } = this.props;
    let dropdowns: SubheaderDropdownProps[] = [];

    if (!isNil(groupByDropdownProps)) {
      dropdowns = concat(dropdowns, {
        ...groupByDropdownProps,
        handleChangeOnDropdown: this.handleChangeGroupByDropdown,
      });
    }

    if (!isNil(floorsetDropdownProps)) {
      dropdowns = concat(dropdowns, {
        ...floorsetDropdownProps,
        handleChangeOnDropdown: this.handleChangeFloorsetDropdown,
      });
    }

    return dropdowns;
  }

  generateSubheaderExtraActionButtonProps(): SubheaderActionButtonProps {
    const {
      dataLoaded,
      massEditConfig,
      configurablePostAction,
      showPublish = false,
      allowWorklistFunctionality = false,
      identifier,
      hideUnpublish = false,
    } = this.props;
    let menuItems: (MenuItemDef | JSX.Element)[] = [];
    if (showPublish) {
      menuItems.push({
        name: this.props.publishText || 'Publish',
        action: () => {
          this.setState({
            actionType: 'publish',
            notificationModal: true,
          });
        },
        disabled: this.getSelectedRows().length === 0,
        icon: 'fal fa-plus-circle',
        tooltip: `${this.props.publishText} selected items`,
      });
      if (!hideUnpublish) {
        menuItems.push({
          name: 'Unpublish',
          action: () => {
            this.setState({
              actionType: 'unpublish',
              notificationModal: true,
            });
          },
          disabled: this.getSelectedRows().length === 0,
          icon: 'fal fa-minus-circle',
          tooltip: 'Unpublish selected items',
        });
      }
    }

    if (!isNil(massEditConfig)) {
      const meMenuItem = createMassEditButton({
        selectedItem: this.getSelectedRows(),
        dataLoading: this.state.gridRdyCnt === 0,
        onClick: () => {
          this.setState({ massEditModal: true });
        },
      });
      menuItems.push(meMenuItem);
    }
    if (allowWorklistFunctionality) {
      menuItems = menuItems.concat(
        getWorklistMultiselectMenuItems(identifier, () => this.gridApi, {
          onPostAction: () => this.gridApi?.deselectAll(),
        })
      );
    }

    if (!isEmpty(configurablePostAction)) {
      const postElement = ConfigurablePostAction({
        action: formatConfigurableActionItem(configurablePostAction, this.handleConfigurablePostAction),
        dataLoading: !dataLoaded,
      });
      menuItems.push(postElement);
    }

    return {
      workListMenuItem: menuItems,
    };
  }

  onGraphItemClick = (data: unknown) => {
    const { configuratorViewDefn, floorsetDropdownProps } = this.props;
    const timeDropdownPresent = !isNil(floorsetDropdownProps);
    const ignoreClicks = this.props.configuratorViewDefn.graph?.ignoreClicks;
    if (ignoreClicks || timeDropdownPresent) {
      return;
    }

    const dataIndex = configuratorViewDefn.graph?.timeDataIndex;
    const nextTime: string = (data as { [key: string]: string })[dataIndex];
    this.props.setFloorsetSelection(nextTime);
  };
  updateSelectedItem = (event: RowSelectedEvent) => {
    const { node } = event;
    if (node.parent && !isNil(node.parent.childrenAfterFilter)) {
      this.debouncedRenderUpdate();
    }
  };

  debouncedRenderUpdate = debounce(() => {
    this.forceUpdate();
  }, 100);

  render() {
    const {
      title,
      configLoaded,
      dataLoaded,
      identifier,
      showFlowStatus,
      massEditConfig,
      showPublish,
      configuratorViewDefn,
      unmodifiedViewDefn,
      defaultCompanionSortField,
      groupByDropdownProps,
      topAttributesData,
      allowWorklistFunctionality = false,
      data,
      leafIdProp,
      dependentCalcs,
      dataApi,
      adornments,
      salesAdjustmentConfig,
      clientActionHandlers,
      updateCoordinateMap,
      groupBySelection,
      configureSelections,
      topMembers,
      isWorklistActive,
      showExtraRow,
    } = this.props;

    if (!configLoaded) {
      return <Overlay type="loading" visible={true} />;
    }

    const { gridScrollTo, activeStyleColor } = this.state;

    const viewConfigurator = this.onUpdateConfig &&
      configuratorViewDefn &&
      unmodifiedViewDefn && {
        viewConfig: configuratorViewDefn,
        configurationSelections: this.props.configureSelections,
        unmodifiedViewDefn: unmodifiedViewDefn,
        updateConfig: this.onUpdateConfig,
        showPinCheckboxForGrid: true,
        companionData: {
          companionSortDirection: this.state.companionSortDirection,
          companionCollapsed: this.state.companionCollapsed,
          companionSortField: this.state.companionSortField,
        },
        defaultCompanionData: {
          companionSortDirection: 'desc' as const,
          companionCollapsed: false,
          companionSortField: defaultCompanionSortField,
        },
      };

    const subheaderProps: SubheaderOwnProps = {
      title: title || '',
      showFlowStatus,
      summary: '',
      showSearch: true,
      extraDropdowns: this.generateSubheaderExtraDropdownProps(),
      extraActionButtons: this.generateSubheaderExtraActionButtonProps(),
      downloadLink: this.props.subheader?.downloadLink,
      errorCondition: this.props.subheaderErrorText,
      viewConfigurator,
      favoritesSaveOverride: {
        groupBySelection: groupByDropdownProps?.selection as number | undefined,
      },
    };
    let configureModalProps: ConfigureModalProps;
    if (!isNil(this.props.configureOptions)) {
      const { configureIsOpen } = this.state;
      subheaderProps.configureOptions = {
        type: 'enabled',
        onConfigureClick: () => {
          this.setState({
            configureIsOpen: true,
          });
        },
      };
      const configureOptions = this.props.configureOptions;
      const updateSelections = this.props.updateConfigureSelections || noop;
      configureModalProps = {
        enabled: true,
        isOpen: !!configureIsOpen,
        ...this.props.configureOptions,
        selections:
          this.state.currentConfigureSelections || this.props.configureSelections || configureOptions.defaultSelections,
        onReset: () => {
          this.setState({
            currentConfigureSelections: configureOptions.defaultSelections || [],
          });
        },
        onToggleModal: (action) => {
          if (isNil(this.state.currentConfigureSelections)) {
            return;
          }
          // this.updateConfigureSelections(configureLastSelected);
          const nextState: Pick<ConfigurableGridState, 'configureIsOpen' | 'currentConfigureSelections'> = {
            configureIsOpen: false,
          };
          switch (action) {
            case 'apply': {
              updateSelections(this.state.currentConfigureSelections);
              break;
            }
            default: {
              nextState.currentConfigureSelections = this.props.configureSelections;
            }
          }
          this.setState(nextState);
        },
        selectionUpdate: (selections: Option[]) => {
          this.setState({
            currentConfigureSelections: selections,
          });
        },
      };
    } else {
      configureModalProps = { enabled: false };
    }

    if (data && data[0]) {
      set(data[0], 'floorset', this.getActiveFloorset());
    }

    const editableGridOptions: EditableGridProps = {
      data: this.props.data,
      columnDefs: this.props.columnDefs,
      gridScrollTo: gridScrollTo,
      gridRowHeight: this.props.gridRowHeight,
      groupRowHeight: this.props.groupRowHeight,
      handleGridReady: (params: GridReadyEvent) => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.setState({ gridRdyCnt: this.state.gridRdyCnt + 1 }); // force a re-render to attach events to the grid
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event && event.data && !event.data.$$GroupHeader) {
          let key = identifier === 'id' ? 'stylecolor' : `member:${identifier}:id`;
          const identityValue = event.data[key];
          const companionData = this.generateCompanionViewData(this.props.data);

          const index = companionData
            ? companionData.findIndex((datum: ListViewable) => {
                const fieldFound = datum[key] ? datum[key] : datum.name;
                // Check if the field is present as a member
                if (datum[`member:${key}:id`] == identityValue) {
                  key = `member:${key}:id`;
                  return true;
                }
                return fieldFound === identityValue;
              })
            : event.data[key];

          this.setState({
            companionScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
            selectedId: companionData[index].id,
            activeStyleColor: event.data['stylecolor'],
          });
        }
      },
      activeFloorset: this.getActiveFloorset(),
      // drilled props
      redecorateMap: this.props.configuratorViewDefn.redecorateMap,
      validValuesCache: this.state.validValuesCache,
      identifier,
      leafIdProp,
      configLoaded,
      activeStyleColor,
      dataLoaded,
      dependentCalcs,
      dataApi,
      adornments,
      salesAdjustmentConfig,
      showPublish,
      clientActionHandlers,
      massEditConfig,
      updateCoordinateMap,
      groupByDropdownProps,
      groupBySelection,
      configureSelections,
      onItemClicked: this.props.onItemClicked,
      onRowSelected: this.updateSelectedItem,
    };

    // TODO: continue to abstract these extraRow components to their own components out of this file
    // to keep slimming this file down (linecount/logic)

    const testArrowValue = (num: number) => {
      if (num > 100000 || num < -100000) {
        return '~';
      }
      if (!isNumber(num)) {
        return '';
      }
      return num;
    };

    let arrowValueGroup: ArrowValueRendererProps[] = [];
    if (topAttributesData && unmodifiedViewDefn && unmodifiedViewDefn.topAttributes) {
      arrowValueGroup = (unmodifiedViewDefn.topAttributes.right as TopAttribute[]).map((config) => {
        const { text = '', dataIndex, renderer } = config;
        const valueRenderer = Renderer[renderer];
        const testValue = testArrowValue(topAttributesData[dataIndex]);
        const finalValue = valueRenderer ? Renderer[renderer](testValue) : testValue;
        return {
          header: text,
          value: finalValue.toString(),
          arrowDirection: this.getArrowFromValue(topAttributesData[dataIndex]),
        };
      });
    }
    const showPublishText = isNil(this.props.showPublishText) ? true : this.props.showPublishText;
    const selectedId = topMembers || this.state.selectedId || '';

    return (
      <div className={listPairStyle}>
        <Subheader {...subheaderProps} />
        <div className="data-container">
          {this.props.hideCompanion === true ? null : this.renderCompanionView(data)}
          <div className="content-container">
            <div className={extraRowContainerStyles}>
              {!isNil(configuratorViewDefn.graph) ? (
                <StyledAccordion
                  title={configuratorViewDefn.graph.title || 'Visualize'}
                  expanded={configuratorViewDefn.graph.expanded}
                >
                  <div style={{ minHeight: `${configuratorViewDefn.graph.height}px`, width: '100%' }}>
                    <MetricLineGraph
                      defnId={configuratorViewDefn.graph.configApi.params?.defnId || ''}
                      selectionRequired={isWorklistActive}
                      selectedId={selectedId}
                      height={configuratorViewDefn.graph.height}
                      timeDataIndex={configuratorViewDefn.graph.timeDataIndex}
                      onItemClick={this.onGraphItemClick}
                    />
                  </div>
                </StyledAccordion>
              ) : null}
              {showExtraRow && (
                <StyledAccordion
                  title={configuratorViewDefn.topAttributes.title || 'Macros'}
                  expanded={
                    isNil(configuratorViewDefn.topAttributes?.expanded)
                      ? true
                      : configuratorViewDefn.topAttributes.expanded
                  }
                >
                  <div className={macrosContainer}>
                    {/* TODO: abstract these to separate components  */}
                    {showPublishText && (
                      <div className={styles.publishedContainer}>
                        {this.generatePublishedText(topAttributesData, unmodifiedViewDefn).map((text: string) => {
                          return <span key={text}>{text}</span>;
                        })}
                      </div>
                    )}
                    <div className={styles.arrowGroup}>
                      {arrowValueGroup.map((config) => {
                        return <ArrowValueRenderer {...config} key={config.header} />;
                      })}
                    </div>
                  </div>
                </StyledAccordion>
              )}
              <div className={gridContainerStyle}>
                <EditableGrid {...editableGridOptions} />
              </div>
            </div>
          </div>
        </div>
        {this.renderConfirmationModal()}
        {this.renderMassEditModal()}
        <ConfigureModal {...configureModalProps} />
      </div>
    );
  }
}
